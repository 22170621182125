<template>
  <div class="panel-admin-body">
    <survey-status :input-survey="survey" :display-survey-status="!isSurveyEmpty" :url="liveSurveyUrl" :does-survey-url-work="this.doesSurveyUrlWork"></survey-status>
    <div class="d-flex title">
      {{ title }}
      <div class="ml-auto">
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <respondent-menu></respondent-menu>
    <div v-if="!permissionToView" class="no-permission text-center">You do not have permission to view this page</div>   
    <div v-else-if="getDisplayView()" class="manage-invitations">
      <div class="survey-mode-section">
        <div class="w-100">
          <modal-link modal-id="Survey Mode" :content-list="pageContent"></modal-link>
          <span class="section-label">Survey Mode</span>
        </div>
        <div class="mode">
          <survey-field
            ref="surveyActiveMode"
            :display-label="false"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-mode')"
            label-class="col-2 pr-2"
            field-class="form-group form-inline mb-2"
            field-type="radio"
            :field-options="radioOptions"
            default-field-name="survey-mode"
            default-field-value="open"
            @save="this.setActiveModeOptions"
          ></survey-field>
          <survey-field
            v-show="displayRespondents"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-closed-number-of-respondents')"
            :display-modal="false"
            label-class="col-4"
            field-class="form-control col-1 p-1"
            field-type="text-box"
            field-label="# of Respondents"
            default-field-value=""
            default-field-name="survey-closed-number-of-respondents"
            @save="this.updateSurveyStats"
          ></survey-field>
          <survey-field
            v-show="displayClosedMessage"
            :survey-id="this.survey.surveyId"
            :survey-field-object="this.getSurveyField('survey-closed-message')"
            :display-modal="false"
            label-class="col-4"
            field-class="form-control col-8 mb-1 p-1"
            field-type="text-box"
            field-label="Survey Closed Message"
            default-field-value=""
            default-field-name="survey-closed-message"
            @save="this.saveSurveyFieldWithSuccessMessage"
          ></survey-field>
        </div>
        <div class="status">
          <div>
            <span class="section-label pl-3">Survey Status: </span>
            <span v-if="this.survey.active == 'Y'" class="text-success"> Active </span>
            <span v-else class="text-danger"> Not Active </span>
          </div>
          <div>
            <span class="section-label pl-3">Build Survey Required: </span>
            <span v-if="this.survey.buildRequired == 'Y'" class="text-danger"> Yes </span>
            <span v-else class="text-success">No</span>
          </div>
          <div v-if="numberOfCompletedSurveys > 0">
            <span class="section-label pl-3"># of Completed Surveys : </span>
            <span class="text-success">{{ this.numberOfCompletedSurveys }} </span>
          </div>
          <div v-if="remainingSurveys > 0">
            <span class="section-label pl-3"># of Remaining Surveys : </span>
            <span class="text-danger">{{ this.remainingSurveys }} </span>
          </div>
        </div>
      </div>
      <div class="thank-you-message-section">
          <div class="form-group form-inline col-6">
            <input type="checkbox" id="required" name="required" v-model="includeCustomThankYouMessage" class="form-check-input" />
            <label for="required" class="custom-checkout">Include Custom Thank You Message</label>
            <div v-show="!isSurveyLocaleEmpty" class="pl-2 form-group form-inline">
                <label for="languageCode" class="pr-2 custom-checkout">By Language</label>
                <select id="languageCode" name="languageCode" class="custom-select" v-model="currentLanguageCode">
                    <option value="en">English</option>
                    <option v-for="(survey_locale_option, idxSurveyLocaleOptions) in this.surveyLocaleList" v-bind:value="survey_locale_option.languageCode" v-bind:key="idxSurveyLocaleOptions">
                        {{ survey_locale_option.countryName }} - {{ survey_locale_option.languageName }}
                    </option>
                </select>
            </div>            
            <button
              type="button"
              class="btn btn-primary ml-2"
              id="EditExistingContentButton"
              v-show="!isContentEmpty && includeCustomThankYouMessage"
              @click="displayEditContentSection = true"
            >
              Edit
              <span class="ml-auto">
                <font-awesome-icon :icon="['fa', 'edit']" />
              </span>
            </button>
          </div>
          <div class="include-signup col-6">
            <survey-field
              :survey-id="this.survey.surveyId"
              :survey-field-object="this.getSurveyField('include-sign-up-message')"
              field-type="checkbox"
              field-class="form-group form-inline"
              field-label="Include wRatings Sign Up Message (included by default)"
              default-field-name="include-sign-up-message"
              :build-survey="false"
              default-field-value="Y"
              @save="this.saveSurveyField"
            ></survey-field>
          </div>
          <div v-if="includeCustomThankYouMessage" class="w-100 mt-3">
            <div v-show="displayEditContentSection">
              <edit-content
                source="manage-invitations"
                :initial-content-id="thankYouMessageContentId"
                :initial-mode="isContentEmpty ? 'add' : 'edit'"
                :initial-page="survey.surveyCode"
                :initial-title="thankYouMessageContentTitle"
                initial-summary-text="Message that will display once the survey is complete"
                :display-summary-field="false"
                :display-page-field="false"
                :display-title-field="false"
                :display-return-to-content-list-button="false"
                close-button-label="Close"
                @save="saveContent()"
                @close="displayEditContentSection = false"
              ></edit-content>
            </div>
            <div v-show="!isContentEmpty && !displayEditContentSection">
              <div class="form-row">
                <p v-html="this.thankYouMessageContent"></p>
              </div>
            </div>
          </div>
      </div>
      <div class="send-invitations-section">
        <div class="w-100">
          <modal-link modal-id="Send Invitations" :content-list="pageContent"></modal-link>
          <span class="section-label">Send Invitations</span>
        </div>
        <div class="survey-url-section">
          <div>
              <div class="form-group form-inline">
                <span>{{ this.baseInterviewUrl + "/" + this.survey.clientId }}/s/</span>
                <input v-model="surveyUrl" id="surveyUrl" name="surveyUrl" type="text" class="form-control col-4" @blur="saveSurveyUrl()" />
                <button v-clipboard="getUrl" v-clipboard:success="clipboardSuccessHandler" class="btn btn-primary float-right ml-2">Copy Link</button>
              </div>
          </div>
          <div class="survey-url-locale-list">
            <div v-for="locale in surveyLocaleList" :key="locale.id" class="w-100 d-flex">
                <div class="url">{{ getFullLocaleUrl(locale) }}</div>
                <div class="mr-3">
                  <div :class="getClass(locale)"></div>
                  <div :class="getTranslationTextClass(locale)">{{ locale.translation }}</div>
                </div>
                <div class="copy-locale-survey-button-container">
                  <button @click="copyUrl(locale)" v-clipboard:success="clipboardSuccessHandler" class="btn btn-primary">Copy Link</button>
                </div>
            </div>
          </div>
          <div style="width: 250px">
            <survey-field
              :field-label="'Default Country'"
              :survey-id="this.survey.surveyId"
              :survey-field-object="this.getSurveyField('default-country')"
              label-class="pr-2 custom-checkout"
              field-class="custom-select"
              field-type="dropdown"
              :field-options="countryOptions"
              default-field-name="default-country"
              default-field-value="US"
              @save="this.saveSurveyFieldWithSuccessMessage"
            ></survey-field>
          </div>
        </div>
        <div class="respondent-codes-section">
          <div class="setup-codes">
            <survey-field
              ref="enableUniqueCode"
              :survey-id="this.survey.surveyId"
              :survey-field-object="this.getSurveyField('enable-unique-codes')"
              field-class="form-group form-inline"
              field-type="checkbox"
              field-label="Use Respondent Codes"
              default-field-name="enable-unique-codes"
              default-field-value=""
              @save="this.enableUniqueCodeUploadEvent"
            ></survey-field>
            <a href="/api/v1/survey-design/download-respondent-codes-import-template" @click.prevent="downloadItem()" id="DownloadResponseCodeImportTemplate">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Download Respondent Codes Template <font-awesome-icon :icon="['fa', 'download']"/></span>
              </button>
            </a>
          </div>
          <div>
            Select a file to upload
            <input ref="uniqueCodeUpload" type="file" @change="onFileChange" :disabled="!useRespondentCodes" />
            <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="this.importFile" :disabled="!useRespondentCodes">Import File</button>
          </div>
        </div>
      </div>
      <div class="unique-codes-section">
        <div class="w-100 pb-2">
          <span class="section-label">Filter Unique Codes</span>
        </div>
        <div class="d-flex w-50 flex-column col-md-12 col-lg-6">
          <div class="row">
            <div class="col-lg-2 col-md-12 col-sm-12">
              <div class="w-100 text-center">&nbsp;</div>
              <filter-by-status @setFilterValue="setFilterByStatusValue" :execute-reset="executeReset"/>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="w-100">Date Created</div>
              <filter-by-date 
                :execute-reset="executeReset"
                @setFilterStartDate="setFilterByStartDateValue"
                @setFilterEndDate="setFilterByEndDateValue"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="w-100">Date Completed</div>
              <filter-by-date 
                :execute-reset="executeReset"
                filter-type="completed"
                @setFilterStartDate="setFilterByStartDateValue"
                @setFilterEndDate="setFilterByEndDateValue"
              />
            </div>
          </div>
          <filter-buttons @reset="reset" @filterData="filterData" :is-data-filtering="isDataFiltering" :is-filter-resetting="executeReset"/>
        </div>
        <div class="d-flex w-50 pt-3 pb-3 col-md-12 col-lg-6">
          <div class="button-left pl-2" style="border-left: 1px solid #c5d4dd !important; ">
            <div class="pL-2">
              <a href="#" @click="showConfirmation">
                <button type="button" class="btn btn-primary" id="ResetUniqueCodeButton" :disabled="!table.rowSelected">
                  Reset Unique Code
                  <span class="ml-auto">
                    <font-awesome-icon :icon="['fas', 'undo']" />
                  </span>
                </button>
              </a>
            </div>        
          </div>        
        </div>
      </div>
      <div class="w-100 pt-3">
        <table id="manage-codes-data" class="table display wrap row-border table-hover" cellspacing="0" width="100%">
          <tbody></tbody>
        </table>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
    </div>
    
  </div>
</template>

<script>
import jQuery from "jquery";
import Vue from "vue";
import moment from "moment";
import EditContent from "../../views/admin/EditContent.vue";

Vue.prototype.moment = moment;
require("jquery-validation");

import RespondentMixins from "../../mixins/RespondentMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import FilterMixins from "../../mixins/FilterMixins.vue";
import SurveyField from "../../components/SurveyField.vue";
import AlertService from "../../services/alert.service";
import GoogleService from "../../services/google.service";
import AdminService from "../../services/admin.service";
import SurveyService from "../../services/survey.service";
import ClientService from "../../services/client.service";
import TableService from "../../services/table.service";
import ContentService from "../../services/content.service";
import LocaleService from "../../services/locale.service";
import SurveyStatus from "../../components/SurveyStatus.vue";
import FilterByStatus from "../../components/filter/FilterByStatus.vue";
import FilterByDate from "../../components/filter/FilterByDate.vue";
import FilterButtons from "../../components/filter/FilterButtons.vue";
import { Clipboard } from 'v-clipboard'

export default {
  name: "ManageInvitations",
  mixins: [RespondentMixins, DataTableMixins,FilterMixins],
  components: {
    SurveyField,
    SurveyStatus,
    EditContent,
    FilterByStatus,
    FilterByDate,
    FilterButtons
  },
  data() {
    return {
      title: "Invitations",
      includeCustomThankYouMessage: false,
      thankYouMessageContentId: 0,
      countryOptions: [],      
      thankYouMessageContent: "",
      surveyContentSections: [],
      displayEditContentSection: false,
      doesSurveyUrlWork: true,
      table: {
        id: "manage-codes-data",
        columns: TableService.getManageInvitationsTableColumnAttributes(),
        configuration: {
          searching: true,
          responsive: false,
          dom: '<"top"fi>rtp<"bottom"B><"clear">',
        }
      },
      pageNumber: 1,
      useRespondentCodes: false,
      uniqueCodefile: "",
      surveyUrl: "",
      currentLanguageCode: "en",
      displayRespondents: false,
      displayClosedMessage: false,
      numberOfCompletedSurveys: -1,
      maximumSurveys: -1,
      radioOptions: [
        { checked: false, name: "Open", value: "open" },
        { checked: false, name: "Closed", value: "closed" },
        { checked: false, name: "Closed (based on completed surveys)", value: "closed-by-respondents" }
      ]
    };
  },
  methods: {
    copyUrl(locale){
      Clipboard.copy(this.getFullLocaleUrl(locale));
      this.clipboardSuccessHandler();
    },
    getFullLocaleUrl(locale){
      return LocaleService.getFullLocaleUrl(locale);
    },
    getClass(locale){
      return LocaleService.getFlagClass(locale);
    },
    getTranslationTextClass(locale){
      return LocaleService.getTranslationTextClass(locale);
    },
    filterData: async function(){
        //console.log("filterData");
        await this.preFilter();
        let vm = this;
        setTimeout(async () => {
            await vm.filterByCreatedDateRange();
            await vm.filterByCompletedDateRange();
            await vm.filterByStatus();
            //console.log("Filter List size after filterByStatus: " + vm.filteredList.length);
            vm.table.data = vm.filteredList;
            vm.setupTable();
            vm.postFilter();
        }, 200);
    },    
    reset: async function(){
        //console.log("reset");
        await this.preReset();
        let vm = this;
        setTimeout(() => {
          vm.table.data = vm.originalList;
          vm.setupTable();
          vm.postReset();
        }, 200);

    },
    clipboardSuccessHandler(){
      AdminService.displaySuccessMessage({ text: "Link Copied" }, true);
    },
    getUrl(){
      return this.fullUrl;
    },
    showConfirmation() {
      let title = 'Delete Confirmation Unique Code';
      let content = 'Are you sure you want reset the unique code ?';
      let parameter = this.table.rowSelectedId;
      let callback = this.resetUniqueCode;
      AlertService.showConfirmationMessageWithParameter(callback,parameter,title,content);                  
    },
    resetUniqueCode(){
      SurveyService.resetSurveyUniqueCode(this.table.rowSelectedId,this.survey.surveyId,this.resetUniqueCodeCallback,this);
    },
    resetUniqueCodeCallback(response){
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.getSurveyUniqueCodesCallback(response);
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    },
    async removeContent() {
      if (this.thankYouMessageContentId != 0){3
        ContentService.removeContentFromStorage(this.survey.surveyCode);
        ContentService.deleteContent(this.thankYouMessageContentId, this.loadContent, this); 
      }
    },
    async findContent() {
      let contentSection = this.surveyContentSections.find(x => x.title == this.thankYouMessageContentTitle);
      if (typeof contentSection != "undefined") {
        this.thankYouMessageContent = contentSection.contentText;
        this.thankYouMessageContentId = contentSection.contentId;
        this.includeCustomThankYouMessage = true;
      } else {
        this.thankYouMessageContent = "";
        this.thankYouMessageContentId = 0;
        this.includeCustomThankYouMessage = false;
      }
    },
    async saveContent() {
      ContentService.removeContentFromStorage(this.survey.surveyCode);
      this.loadContent();
    },
    async loadContent() {
      await ContentService.getPageContent(this.survey.surveyCode, this.getSurveyPageContentCallBack, this);
      this.displayEditContentSection = false;
      this.findContent();
    },
    getSurveyPageContentCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyContentSections = response.data.data;
      }
    },
    updateSurveyStats: function(result) {
      if (!isNaN(result.surveyFieldValue)) {
        this.maximumSurveys = Number(result.surveyFieldValue);
      } else {
        this.maximumSurveys = -1;
      }
      this.saveSurveyFieldWithSuccessMessage(result);
    },
    setActiveModeOptions: function(result) {
      if (result.surveyFieldValue == "closed-by-respondents") {
        this.displayRespondents = true;
        this.displayClosedMessage = true;
      } else if (result.surveyFieldValue == "closed") {
        this.displayRespondents = false;
        this.displayClosedMessage = true;
      } else {
        this.displayRespondents = false;
        this.displayClosedMessage = false;
      }
    },
    onFileChange: function() {
      this.uniqueCodefile = this.$refs.uniqueCodeUpload.files[0];
    },
    async importFile() {
      if (this.uniqueCodefile){  
        this.$root.processServerRequest("Importing unique codes");        
        await SurveyService.importFile(this.uniqueCodefile, this.survey.surveyId, this.fullUrl, this.importFileCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
      }
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.getSurveyUniqueCodesCallback(response);
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveSurveyUrl() {
      if (this.survey.url != this.surveyUrl) {
        this.$root.processServerRequest("Saving survey URL");
        await SurveyService.saveSurveyUrl(this.surveyUrl,this.survey.surveyId, this.clientCode, this.saveSurveyUrlCallback, this);
      }
    },
    saveSurveyUrlCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        GoogleService.logClickEvent("Change Survey URL", this.surveyUrl, 0, this);
        this.surveyUrl = response.data.data.url;
        this.survey.url = response.data.data.url;
        if (!jQuery.isEmptyObject(response.data.data.localization)) {
          this.localization = response.data.data.localization;
        }
        if (!jQuery.isEmptyObject(response.data.data.surveyCodes)) {
          this.clientObject.surveyCodes = response.data.data.surveyCodes;
          ClientService.saveClient(this.clientObject);
          this.$root.setClientObject(this.clientObject);
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    enableUniqueCodeUploadEvent: function(result) {
      this.saveSurveyField(result);
      this.useRespondentCodes = result.surveyFieldValue == "Y";
    },
    async postLoadData() {
      if (!this.isW5OUser){
        this.getTableData();
        this.setDisplayView(true);
        if (typeof this.getSurveyField("enable-unique-codes") != "undefined") {
          this.useRespondentCodes = this.getSurveyField("enable-unique-codes").surveyFieldValue;
        }
        if (typeof this.getSurveyField("survey-closed-number-of-respondents") != "undefined") {
          this.maximumSurveys = Number(this.getSurveyField("survey-closed-number-of-respondents").surveyFieldValue);
        }
        if (typeof this.getSurveyField("survey-mode") != "undefined") {
          this.setActiveModeOptions(this.getSurveyField("survey-mode"));
        }
        await LocaleService.getCountryOptionsForLocale(this.getCountryOptionsForLocaleCallback, this);
        this.surveyUrl = this.survey.url;
        this.doesSurveyUrlWork = await SurveyService.doesSurveyUrlWork(this.survey);
        await this.loadContent();
      }
    },
    getCountryOptionsForLocaleCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.countryOptions = response.data.data.countryOptions;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }    
    },    
    getSurveyUniqueCodesCallback(response){
      this.originalList = response.data.data;
      this.getTableDataCallback(response);
    },
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      await SurveyService.getSurveyUniqueCodesBySurveyId(this.survey.surveyId, this.getSurveyUniqueCodesCallback, this);
    },
    getDownloadFilename() {
      return "Respondent Codes Import Template";
    },
    async downloadItem() {
      this.setupDownloadItem();
      await SurveyService.getDownloadRespondentCodesTemplate(this.downloadItemCallback, this);
    }
  },
  computed: {
    thankYouMessageContentTitle: function(){
      if (this.currentLanguageCode == "en"){
        return "Thank You Message";  
      }
      return "Thank You Message - " + this.currentLanguageCode;
    },
    isSurveyLocaleEmpty: function(){
      return this.localization.surveyLocale.length == 0;
    },
    surveyLocaleList: function(){
      return this.localization.surveyLocale.filter(x => x.languageCode != "en");
    },
    baseInterviewUrl: function() {
      return SurveyService.getBaseInterviewUrl();
    },
    fullUrl: function() {
      return this.baseInterviewUrl + "/" + this.survey.clientId + "/s/" + this.surveyUrl;
    },
    remainingSurveys: function() {
      if (this.maximumSurveys > 0 && this.numberOfCompletedSurveys > 0) {
        return this.maximumSurveys - this.numberOfCompletedSurveys;
      }
      return -1;
    },
    isContentEmpty: function() {
      return this.thankYouMessageContent == "";
    }
  },
  created() {},
  props: {},
  mounted() {
    this.table.configuration.order = [[6, "desc"]];
  },
  watch: {
    currentLanguageCode: function(){
      this.findContent();
    },
    includeCustomThankYouMessage: function(){
      if (this.includeCustomThankYouMessage == false){
        this.removeContent();
      } else if (this.isContentEmpty) {
        this.displayEditContentSection = true;
      }
    }
  }
};
</script>
