<template>
    <div class="col-10 no-gutters container mt-3">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import PredictiveAnalyticsService from "../../services/echarts/predictive.analytics.service";
import TableService from "../../services/table.service";

import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "PredictiveAnalyticsChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        coorelationMessages: [],
        chartTitle: "Metric Trends",
        metricDisplayTypeName: "Currency",
        metricDisplayTypeNameForSecondary: "Currency",
        useMetricGrowth: false,
        useMetricGrowthForSecondary: false,
        addWrapper: false,
        correlation: 0.62,
        correlationClass: "",
        correlationMessage: "",
        min: 0,
        max: 10,
        minSecondary: 0.01,
        maxSecondary: 10,
    };
  },
  props: {},
  computed: {
    correlationColor: function(){
        if (this.correlationClass == "very-strong-correlation"){
            return  "#548235";
        } else if (this.correlationClass == "strong-correlation"){
            return  "#70AD47";
        } else if (this.correlationClass == "mild-correlation"){
            return  "#A9D18E";
        } 
        return  "#000";
    },
  },
  mounted() {},
  created() {},
  methods: {
    //https://codepen.io/plainheart/pen/bGgmGPj    
    setDataForPredictiveAnalytics: function(chartData,chart1,chart2 = {},diplayType){
        this.setDataForBaseChart(chartData,chart1,chart2,diplayType);
        let chart1Data = {};
        let chart2Data = {};        
        if (typeof chartData != "undefined" && !$.isEmptyObject(chartData)) {
            if (typeof chartData.chart1 != "undefined" && chartData.chart1.data.length > 0){
                chart1Data = JSON.parse(JSON.stringify(chartData.chart1));
                if (typeof chartData.chart1.containerId != "undefined"){
                    chart1.containerId = chartData.chart1.containerId;
                }
            }                
            if (typeof chartData.chart2 != "undefined" && chartData.chart2.data.length > 0){
                chart2Data = JSON.parse(JSON.stringify(chartData.chart2));
                if (typeof chartData.chart2.containerId != "undefined"){
                    chart2.containerId = chartData.chart2.containerId;
                }
            }           
        }        
        let metricData = chart1Data.data[0]; 
        let yAxis = 0;
        for (let dataIndex = 0; dataIndex < metricData.length; dataIndex++) {       
            let metric = metricData[dataIndex];
            let legendName = metric.axisName;
            let useMetricGrowth = metric.useMetricGrowth;
            let metricGroup = metric.metricGroup;
            let isPrimary = metric.isPrimary;
            let isSecondary = metric.isSecondary;
            let metricDisplayTypeName = metricGroup != null ? metricGroup.metricDisplayTypeName : "";
            if (typeof legendName != "undefined"){
                if (isPrimary){
                    yAxis = 1;
                    this.useMetricGrowth = useMetricGrowth;
                    this.metricDisplayTypeName = metricDisplayTypeName;
                    if (this.useMetricGrowth == true) {
                        this.metricDisplayTypeName = "Percentage";
                    }                    
                    chart1.series.push(PredictiveAnalyticsService.getSeriesForPredictiveAnalyticsChart(legendName,0,0,0,useMetricGrowth,metricDisplayTypeName,null,this.eChartCriteria));
                } else {
                    if (isSecondary == true){
                        this.useMetricGrowthForSecondary = useMetricGrowth;
                        if (this.useMetricGrowthForSecondary == true) {
                            this.metricDisplayTypeNameForSecondary = "Percentage";
                        }
                    }
                    chart1.series.push(PredictiveAnalyticsService.getSeriesForPredictiveAnalyticsChart(legendName,0,0,1,useMetricGrowth,metricDisplayTypeName,"rec",this.eChartCriteria));
                }
            }
        }
        chart1 = this.setChart(chart1Data, chart1);
        this.charts.push(chart1);
        if (!$.isEmptyObject(chart2Data)){
            chart2 = this.setChart(chart2Data, chart2);
            this.charts.push(chart2);
        }
    },
    setChart: function(chartData, chart){
        chart = this.clearChart(chart);
        let xAxisData = [];
        this.coorelationMessages = [];
        let metricData = chartData.data[0]; 
        for (let dataIndex = 0; dataIndex < metricData.length; dataIndex++) {
            let metric = metricData[dataIndex];
            let index = metric.index
            let metricRow = metric.data;
            let name = metric.name;
            let axisName = metric.axisName;
            let useMetricGrowth = metric.useMetricGrowth;
            let metricGroup = metric.metricGroup;
            let metricDisplayTypeName = metricGroup != null ? metricGroup.metricDisplayTypeName : "";
            for (let rowIndex = 0; rowIndex < metricRow.length; rowIndex++) {
                let metricProperties = metricRow[rowIndex];
                if (typeof metricProperties.displayTypeName != 'undefined' && metricProperties.displayTypeName != null ) {
                    let wave = metricProperties.wave;
                    if (name == "primary"){
                        xAxisData.push(wave);
                    } 
                    chart.series[index].data.push(PredictiveAnalyticsService.getData(metricProperties, axisName, useMetricGrowth,metricDisplayTypeName));
                }
            }
            if (metric.isPrimary != true){
                if (metric.isSecondary == true){
                    this.correlationClass = metric.correlationClass;
                }
                let correlation = Number(JSON.parse(JSON.stringify( metric.correlationData))).toFixed(2);
                let message = axisName + " = " + correlation + " " + metric.correlationMessage + " " + "\n";
                this.coorelationMessages.push(message);
            }
        }
        chart.xAxis.data = JSON.parse(JSON.stringify(xAxisData));
        let dataValues = chart.series[0].data.filter(entry=> !isNaN(entry.value)).map(entry => entry.value);
        let values = PredictiveAnalyticsService.findMinMax(dataValues,this.useMetricGrowth);
        this.min = values.min;
        this.max = values.max;
        dataValues = [];
        for (let i = 1; i < chart.series.length; i++) {
            let seriesDataValues =  chart.series[i].data.filter(entry=> !isNaN(entry.value)).map(entry => entry.value);
            dataValues = dataValues.concat(seriesDataValues);
        }
        let valuesSecondary = PredictiveAnalyticsService.findMinMax(dataValues,this.useMetricGrowthForSecondary);
        this.minSecondary = valuesSecondary.min;
        this.maxSecondary = valuesSecondary.max;
        return chart;
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let showLegend = true;
        let showLabel = this.eChartCriteria.showLabels;
        let angleText = true;
        let isMultipleWaves = true;
/* Start of function that should go into helper service */
        let xAxis = [];
        let seriesData = [];
        for (let i = 0; i < this.charts.length; i++) {
            let chart = this.charts[i];
            for (let i = 0; i < chart.series.length; i++) {
                let series = chart.series[i];
                seriesData.push(series);
            }
            $.extend(true, chart.xAxis.axisLabel, PredictiveAnalyticsService.getDefaultAxisTextStyleForAnalyticChart());
            if (angleText == true){           
                $.extend(true, chart.xAxis.axisLabel, {rotate: 25,hideOverlap: true,interval: 0});
            } else {
                let defaultAxisLabel = {
                    overflow: "break",
                    interval: 0,
                    width: 40,
                    ellipsis: '...',        
                    rotate: 0,
                    hideOverlap: false,
                };
                $.extend(true, chart.xAxis.axisLabel, defaultAxisLabel);
            }
            let breakPointList = [];
            let numberOfWaves = chart.xAxis.data.length;
            if (numberOfWaves > 4){
                let numberOfBreakPoints = Math.floor(numberOfWaves / 4);
                for (let i = 1; i <= numberOfBreakPoints; i++) {
                    breakPointList.push(i * 4);
                }
                let splitLine = {
                    show: true,
                    interval: function(index, value) {
                        return breakPointList.includes(index);              
                    },             
                };
                $.extend(true, chart.xAxis.splitLine, splitLine);
            }
            xAxis.push(chart.xAxis);
            xAxis.push(chart.header);
        }
/* End of function that should go into helper service */
        ScatterService.setShowSnap(this.isSavedCard);
        ScatterService.setShowToolbox(showToolbox);
        ScatterService.setShowLabel(showLabel);
        ScatterService.setShowLegend(showLegend);
        ScatterService.setIsMultiWave(isMultipleWaves);
        ScatterService.setChartType(this.eChartCriteria.eChartType);
 //       let legendData = this.legend.normal.map(entry => entry.name);
        ScatterService.setLegendData(this.legend.normal);
        ScatterService.setSeriesData(seriesData);
        let yAxis = [];
        let primaryAxis = PredictiveAnalyticsService.getYAxisForNeed(this.min, this.max);
        let secondaryAxis = PredictiveAnalyticsService.getYAxisForNeed(this.minSecondary, this.maxSecondary);
        secondaryAxis.splitLine.show = false;
        primaryAxis = PredictiveAnalyticsService.setDataLabels(primaryAxis,this.useMetricGrowth,this.metricDisplayTypeName);
        secondaryAxis = PredictiveAnalyticsService.setDataLabels(secondaryAxis,this.useMetricGrowthForSecondary,this.metricDisplayTypeNameForSecondary);
        yAxis.push(primaryAxis);
        yAxis.push(secondaryAxis);
        
        ScatterService.setYAxis(yAxis);
        ScatterService.setXAxis(xAxis);
        options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName);
        if (!$.isEmptyObject(options)) { 
            options.tooltip.backgroundColor = "#ffffff";
            options.tooltip.formatter =  PredictiveAnalyticsService.toolTipForPredictiveAnalyticsChart;
            options.color = this.colors;
            options.grid[0].top = 20;
           // options.grid[0].bottom = 60;
            options.legend.top = 20;
            $.extend(true, options.toolbox, PredictiveAnalyticsService.getToolboxForAnalyticsChart());
            let subtext = "";
            for (let i = 0; i < this.coorelationMessages.length; i++) {
                subtext += this.coorelationMessages[i];
            }
/*            
			let titleConfiguration = {
					text: "",
                    bottom: 0,
					//left: 'center',
                    left: 100,
                    textStyle: {
                        fontFamily: "Franklin Gothic Demi Cond",
                        color: "#2F5EB3"
                    },
                    //subtext: 'r = ' + this.correlation + ' ' + this.correlationMessage,
                    subtext: subtext,
                    subtextStyle:{
                        color: this.correlationColor,
                        fontFamily: "Franklin Gothic Demi Cond",
                        fontSize: 15
                    }
			};             
            options.title = titleConfiguration;
*/            
        }
        return options;           
    },
    processChart: function(runChart = false,displayType,chartData,eChartCriteria){
        if (runChart == true) {
            this.displayType = displayType;
            if (typeof eChartCriteria != "undefined") {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            }
            // get the splits for charts
            let breakPointList = [2,8];
            if (displayType == "predictive-analytics") {
                let chart1 = {
                    containerId: "",
                    header: [],
                    series: [],
                    xAxis: PredictiveAnalyticsService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(PredictiveAnalyticsService.getGridForSingleChart());
                this.setDataForPredictiveAnalytics(chartData,chart1,{},displayType);
                this.initChart();
            } 
            if (typeof chartData != "undefined" && typeof eChartCriteria != "undefined" && this.charts.length > 0) {
                this.$emit('save-criteria',true);
                this.$emit('save-table');
            }
        }
    },
  },
  watch: {},
};
</script>
