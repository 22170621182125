<template>
  <div class="w-100 mt-2 mb-2">
    <div v-if="disableButton"><span class="p-1 alert alert-warning">The question must be saved first before the valid options can be loaded</span></div>
    <div class="pr-2 pb-2 pt-2">
        <a href="#" @click="showImportModal">
            <button type="button" class="btn btn-primary" :disabled="disableButton">
                <span class="align-text-bottom">Upload Valid Options <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
        </a> 
    </div>  
    <div>Current Options ({{ numberOfValidResponses }}): {{ optionsDisplay }}</div>
    
    <import-valid-options :question-id="questionId" :current-options="optionsDisplay" @imported="loadData" :number-of-options="numberOfValidResponses"/>              
  </div>
</template>
<script>

import ImportValidOptions from "../modals/ImportValidOptions.vue";
import QuestionService from "../services/question.service";

export default {
  name: "QuestionTypeAhead",
  components: {
    ImportValidOptions
  },
  computed: {
    disableButton() {
      return this.questionId == 0;
    },
    numberOfValidResponses: function(){
      if (this.validOptions.length > 0){
        return this.validOptions[0].validResponses.split(",").length;
      }
      return 0;
    },    
  },
  watch: {},
  data() {
    return {
        validOptions: [],
        optionsDisplay: "None"
    };
  },
  props: {
    questionId: {
        type: Number,
        value: 0
    },    
  },
  methods: {
    loadData(){
        QuestionService.getValidOptions(this.questionId, this.getValidOptionsCallback, this);
    },
    showImportModal(event){
        event.preventDefault();
        this.returnMessage = "";
        this.$modal.show('import-valid-options');
    },       
    getValidOptionsCallback(response) {
      if (response.status === 200) {
        this.validOptions = response.data.data;
        if (this.validOptions.length > 0){
            this.optionsDisplay = this.validOptions[0].validResponses;
            this.optionsDisplay = this.optionsDisplay.length > 1000 ? this.optionsDisplay.substring(0, 10) + "..." : this.optionsDisplay;
        } else {
            this.optionsDisplay = "None";
        }
      } else {
        this.validOptions = [];
      }
    },
  },
  mounted() {
    if (this.questionId != 0){
        this.loadData();
    }
  },
  umnounted() {}
};
</script>
