<template>
  <div>
    <div id="chart-container" class="row">
      <div id="moat-trends-chart" class="moat-trends col-12">
        <div id="moat-trends-title-and-chart" class="title-and-chart">

          <profile-chart-header
              :title="chart.title"
              :display="false"
              :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
              @set-display-number-of-responses="setDisplayNumberOfResponses"
           />
          <trends-chart ref="moatTrendsEChart"
              :e-chart-mini-id="'moat-trends-e-chart-mini'"
              :e-chart-id="'moat-trends-e-chart'"
              :input-report-type-name="'Chart - Moat Trends'"
              :modal-id="'moat-trends-chart'"
              :is-saved-card="this.$parent.clientChart.clientChartId != 0" 
              :input-client-chart="this.$parent.clientChart"
              @save-table="saveProfileDataTable"
              @save-criteria="buildClientChart"/>  
        </div>
      </div>
      <div class="col-12">
        <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="moat-trends-data-container" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
          <div v-show="isInsiderUser" class="overlay">
            <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png"  style="width: 50%"/>
          </div>
          <chart-data-table
            :data-container-id="'moat-trends-data-container'"
            :container-id="'moat-trends'"
            :report-page-type-name="'Table - Moat Trends'"
            :insight-modal-id="'moat-trends-table'"
            :chart-data="this.table.data"
            :row-start-position="6"
            :column-defs="this.chart.columnDefs"
            :is-saved-card="this.$parent.clientChart.clientChartId != 0" 
            :table-class="'table display row-border table-hover'"
            :table-width="'auto'"
            :table-title="this.table.title"
          ></chart-data-table>
        </div>
      </div>
    </div>
    <insights
      ref="insightsChart"
      :modal-name="'moat-trends-chart'"
      :report-page-type-name="'Chart - Moat Trends'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'moat-trends-table'"
      :report-page-type-name="'Table - Moat Trends'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import TrendsChart from "../../../views/echarts/TrendsChart.vue";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";

import ReportService from "../../../services/report.service";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import TableService from "../../../services/table.service";
import TrendsService from "../../../services/echarts/trends.service";

export default {
  name: "MoatTrend",
  mixins: [ChartCompanyMixins],
  components: { TrendsChart,ProfileChartHeader },
  data() {
    return {
      title: "Moat Trends",
      pageNumber: 5,
      table: {
        id: "",
        data: {},
        title: '<h4 class="text-primary">Moat Trends</h4>'
      },
    };
  },
  props: {
    chartData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Moat Trends") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Moat Trends") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    parseForTrends:function(inputData){
      let itemsToRemove = {
          lastColumns: 0,
          firstColumns: 0
        };
      let data = {};
      let chart1Data = inputData;
      data = TrendsService.parseData(1,chart1Data,data,itemsToRemove,false);
      data = TrendsService.parseLegendAndColorData(chart1Data,data,false);
      data = TrendsService.parseAxisData(chart1Data,data);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },        
    saveAllReportPages() {
      this.saveReportPage("moat-trends");
    },
    async saveProfileDataTable(containerId) {
      if (this.clientChart.clientChartId != 0  && this.isGenerateReportRunning()) {
        await ReportService.saveReportPage(
          this.getReportTableContentByTable("dataTableVertical_" + containerId + "_wrapper"),
          "Table - Moat Trends",
          this.$parent.clientChart,
          this.saveReportPageCallback,
          this
        );
      }
    },     
    async processChartData() {
      try {
        let moatData = JSON.parse(JSON.stringify(this.chartData[0]));
        this.chart = ChartService.getChartAttributes("Moat Trends");
        let otherData = moatData["otherData"];
        
        let companyColorList = otherData["companyColorList"];
        TableService.setCompanyColorList(companyColorList);
        let containerId = moatData["containerId"];
        let isMoatTrend = otherData["isMoatTrends"];
        this.$parent.setChartResults(moatData.companyList);
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(moatData.criteriaList, "Competitor");
        this.table.id = containerId;
        this.table.data = moatData;
        
        this.$root.$emit("check-number-of-responses");
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
        this.$root.processServerResponse();
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria,true);
        this.parseForTrends(moatData);
        this.setDefaultClientChartName();
        this.$refs.moatTrendsEChart.processChart(true,'moat-trends',this.eChartData,this.eChartCriteria,isMoatTrend);
        $("#moat-trends-title-and-chart").addClass("chart-wrapper");
      } catch (err) {
        ErrorService.processException("MoatTrend.displayChartCallback", err, "chart", this);
      }
    },
    setDefaultClientChartName() {
      if (this.$parent.saveClientChartObject.clientChartId == 0) {
        let displayType = this.$parent.$refs.chartCriteria.$data.display_type_options.find(x => x.value == this.$parent.$refs.chartCriteria.$data.displayType);
        if (typeof displayType != "undefined" && displayType != null && displayType != "" && typeof displayType.name != "undefined") {
          this.chart.title = displayType.name;
          this.table.title = '<div class="title"><h4 class="text-primary">' + this.chart.title + '</h4></div>';
        }
        this.$parent.clientChart.name = this.chart.title + " " + this.$parent.$refs.chartCriteria.getPeriodName(this.$parent.clientChart.clientTimePeriodId);
      }
    },    
  },
  umnounted() {},
  computed: {},
  mounted() {},
  created() {},
  watch: {
    chartData: function() {
      if (this.$parent.isMoatTrend) {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
