<template>
  <div class="section shadow mt-2 mb-2">
    <div v-show="!isEditMode">
      <div class="row no-gutters section-header pl-2">
        <div class="align-self-center col-9">{{ section.sequenceNumber }}. {{ this.sectionTypeName }}</div>
        <div :class="[sectionTypeName == 'Emotional Needs' ? 'align-self-center col-3 d-line ' : 'align-self-center col-3 section-actions']">
          <button type="submit" class="btn btn-primary float-right ml-2 mr-2" v-on:click="mode = 'edit'">{{ primaryButtonText }}</button>
          <button type="submit" class="btn btn-primary float-right ml-2 mr-2" v-show="!isNeedsSection" v-on:click="openQuestionOrder">Edit Question Order</button>
        </div>
      </div>
      <div v-show="!isQuestionOrderMode" class="row no-gutters justify-content-end">
        <div class="col-12">
          <div v-if="displayHeader" class="header p-2">{{ this.sectionHeader }}</div>
          <div class="description">{{ sectionDescription }}</div>
        </div>
      </div>
      <div v-show="isQuestionOrderMode">
        <question-order :input-questions="this.questions" @save="questionOrderCallback" @enable-save-order-button="activateSaveQuestionOrderButton"></question-order>
        <div class="row no-gutters mt-2 mb-2 justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2" :disabled="!this.enableSaveOrderButton" v-on:click="this.saveQuestionOrder">
              <span>Save Order</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button type="submit" class="btn btn-primary float-right ml-2" v-on:click="mode = 'view'">Close</button>
        </div>
      </div>
      <div v-show="!isQuestionOrderMode" class="row no-gutters" v-for="(question, idxQuestion) in this.activeQuestions" v-bind:key="idxQuestion">
        <question
          v-if="question.active == 'Y'"
          :ref="idxQuestion"
          :question-index="idxQuestion"
          :question-object="question"
          :section-object="section"
          :display-details="true"
          :display-required="displayRequired"
          :next-sequence-number="activeQuestions.length"
          :display-include-as-segment="displayIncludeAsSegment"
          :is-question-in-emotional-section="false"
          :widget-type-options="widgetTypeOptions.options"
          :disable-edit-mode="disableEditMode"
        ></question>
      </div>
      <div v-show="!isQuestionOrderMode" class="row no-gutters justify-content-end">
        <div class="form-group mr-2" v-if="(displayRandomizeEmotionalQuestions || displayRandomizeFunctionalQuestions) && this.randomizeQuestionFieldName != ''">
          <survey-field
            :survey-id="surveyId"
            :survey-field-object="this.$parent.getSurveyField(this.randomizeQuestionFieldName)"
            field-type="checkbox"
            field-class="form-group form-inline"
            field-label="Randomize Questions"
            :default-field-name="this.randomizeQuestionFieldName"
            default-field-value="Y"
          ></survey-field>
        </div>
      </div>
      <div class="row no-gutters" v-show="isAddQuestionMode && !isEditMode && !isQuestionOrderMode">
        <add-question
          :new-question-index="this.activeQuestions.length + 1"
          :survey-id="this.surveyId"
          :section-id="this.sectionId"
          :display-required="displayRequired"
          :display-include-as-segment="displayIncludeAsSegment"
          :widget-type-options="widgetTypeOptions.options"
        ></add-question>
      </div>
      <div v-show="!isQuestionOrderMode && !isNeedsSection" class="row no-gutters">
        <div class="col text-center mb-3">
          <button type="submit" class="btn btn-primary" v-on:click="mode = 'add-question'">Add Question</button>
        </div>
      </div>
    </div>
    <div v-show="isEditMode" class="m-3">
      <div class="form-row form-group">
        <label for="sectionName" class="col-2">Name</label>
        <input v-model="sectionName" :id="'sectionName_' + sectionId" name="sectionName" type="text" class="col-10" />
      </div>
      <div class="form-row form-group">
        <label for="sectionHeader" class="col-2">Header</label>
        <input v-model="sectionHeader" :id="'sectionHeader_' + sectionId" name="sectionHeader" type="text" class="col-10" />
      </div>
      <div class="form-row form-group">
        <label for="sectionDescription" class="align-top col-2">Description</label>
        <textarea class="col-10" :id="'sectionDescription_' + sectionId" name="sectionDescription" v-model="sectionDescription" rows="5"> </textarea>
      </div>
      <div class="form-row justify-content-end" role="group">
        <button type="submit" class="btn btn-primary mr-2" v-on:click="this.saveSection">Save Section</button>
        <button class="btn btn-primary" v-on:click="mode = 'view'">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import Question from "../components/Question.vue";
import SurveyField from "../components/SurveyField.vue";
import AddQuestion from "../components/AddQuestion.vue";
import QuestionOrder from "../components/QuestionOrder.vue";
import SectionMixins from "../mixins/SectionMixins.vue";

import jQuery from "jquery";
import $ from "jquery";
import AdminService from "../services/admin.service";
import SectionService from "../services/section.service";
import QuestionService from "../services/question.service";
import GoogleService from "../services/google.service";
import SurveyService from "../services/survey.service";
import ErrorService from "../services/error.service";

export default {
  name: "SurveySection",
  mixins: [SectionMixins],
  components: {
    Question,
    AddQuestion,
    QuestionOrder,
    SurveyField
  },
  data() {
    return {
      mode: "view",
      enableSaveOrderButton: false,
      widgetTypeOptions: {},
      maximumNumberOfChartSegments: 999,
      totalNumberOfChartSegments: 0,
      randomizeQuestionFieldName: ""
    };
  },
  props: {
    surveyId: {
      type: Number,
      default: 0
    },
    surveyName: {
      type: String,
      default: ""
    },
    surveyContentSections: {
      type: Array,
      default: function() {
        return [];
      }
    },
    surveyTemplateObject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    initialMode: {
      type: String,
      default: "view"
    }
  },
  computed: {
    primaryButtonText: function() {
      if (this.mode == "edit") {
        return "Save Section";
      } else {
        return "Edit Section";
      }
    },
    activeQuestions: function() {
      return this.questions.filter(entry => entry.active == "Y");
    },
    displayRequired: function() {
      //return this.sectionObject.sectionType.name == "Segmentation Information" || this.sectionObject.sectionType.name == "Personal Information";
      return true;
    },
    displayIncludeAsSegment: function() {
      return this.sectionObject.sectionType.name == "Segmentation Information";
    },
    isEditMode: function() {
      return this.mode == "edit";
    },
    displayHeader: function() {
      return !jQuery.isEmptyObject(this.sectionHeader);
    },
    isQuestionOrderMode: function() {
      return this.mode == 'edit-question-order';
    },
    isNeedsSection: function(){
      return this.sectionTypeName == "Functional Needs" || this.sectionTypeName == "Emotional Needs"
    },
    isAddQuestionMode: function() {
      return this.mode == "add-question";
    },
    displayRandomizeEmotionalQuestions: function() {
      return this.sectionTypeName === "Emotional Needs";
    },
    displayRandomizeFunctionalQuestions: function() {
      return this.sectionTypeName === "Functional Needs";
    },
    disableEditMode: function() {
      return (
        (this.sectionName != "Actual Functional Needs" && this.sectionTypeName == "Functional Needs") ||
        (this.sectionName != "Actual Emotional Needs" && this.sectionTypeName == "Emotional Needs")
      );
    }
  },
  methods: {
    questionOrderCallback(result) {
      this.questions = JSON.parse(JSON.stringify(result));
    },
    openQuestionOrder : function(){
      if (this.mode == 'view'){
        this.mode = 'edit-question-order';
      } else {
        this.mode = 'view';
      }
    },
    updateQuestions: function(data){
      this.questions =  JSON.parse(JSON.stringify(data));
    },
    activateSaveQuestionOrderButton(){
      this.enableSaveOrderButton = true;
    },
    saveQuestionOrder(){
      let questionsInOrder = QuestionService.convertQuestiontoQuestionDto(this.questions);
      this.$root.processServerRequest("Saving question order");
      GoogleService.logClickEvent(SurveyService.getSurveyName(), "Save Order - Question", 0, this);
      QuestionService.saveQuestionOrder(questionsInOrder, this.sectionId, this.saveQuestionOrderCallback, this);
    },    
    saveQuestionOrderCallback(response){
      this.$root.processServerResponse();      
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.updateQuestions(response.data.data);
        this.enableSaveOrderButton = false;
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        ErrorService.processError("SurveySection.saveQuestionOrderCallback", response.data.message, "survery-designer", this);
      }
    },    
    saveSectionCallback: function(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        $.extend(this.section, response.data.data);
        this.mode = "view";
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async saveSection() {
      SectionService.saveSection(JSON.stringify(this.section), this.saveSectionCallback, this);
    },
    setTotalNumberOfChartSegments() {
      if (this.displayIncludeAsSegment) {
        let numberOfChartSegments = 0;
        this.activeQuestions.forEach(function(question) {
          if (question.includeAsSegment == "Y") {
            numberOfChartSegments++;
          }
        });
        this.totalNumberOfChartSegments = numberOfChartSegments;
      }
    }
  },
  created() {
    this.widgetTypeOptions = JSON.parse(this.surveyTemplateObject.configuration).widgets;
    this.randomizeQuestionFieldName = this.randomizeFieldName;
  },
  mounted() {
    this.setTotalNumberOfChartSegments();
    this.mode = this.initialMode;
  }
};
</script>
