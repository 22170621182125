import axios from "axios";
import authHeader from "./auth-header";
import ErrorService from "./error.service";
import AdminService from "./admin.service";
import UserService from "./user.service";
import SurveyService from "./survey.service";
import ClientService from "./client.service";
import TableService from "./table.service";
import AlertService from "./alert.service";

import moment from "moment";
import Vue from "vue";
import $ from "jquery";

Vue.prototype.moment = moment;

const API_CLIENT_URL = AdminService.getBaseUrl() + "/api/v1/client";
//const X_SYMBOL = 'path://M797.32 985.882 344.772 1438.43l188.561 188.562 452.549-452.549 452.548 452.549 188.562-188.562-452.549-452.548 452.549-452.549-188.562-188.561L985.882 797.32 533.333 344.772 344.772 533.333z';
const X_SYMBOL = 'path://M84.707,68.752L65.951,49.998l18.75-18.752c0.777-0.777,0.777-2.036,0-2.813L71.566,15.295 c-0.777-0.777-2.037-0.777-2.814,0L49.999,34.047l-18.75-18.752c-0.746-0.747-2.067-0.747-2.814,0L15.297,28.431 c-0.373,0.373-0.583,0.88-0.583,1.407c0,0.527,0.21,1.034,0.583,1.407L34.05,49.998L15.294,68.753 c-0.373,0.374-0.583,0.88-0.583,1.407c0,0.528,0.21,1.035,0.583,1.407l13.136,13.137c0.373,0.373,0.881,0.583,1.41,0.583 c0.525,0,1.031-0.21,1.404-0.583l18.755-18.755l18.756,18.754c0.389,0.388,0.896,0.583,1.407,0.583c0.511,0,1.019-0.195,1.408-0.583 l13.138-13.137C85.484,70.789,85.484,69.53,84.707,68.752z';
const BAR_SYMBOL = 'path://M0,0c99,0 198,0 300,0c0,23.76 0,47.52 0,72c-99,0 -198,0 -300,0c0,-23.76 0,-47.52 0,-72z';

class SymbolService {
    async getSymbolTypeOptions(callback, app){
      let params = new URLSearchParams();
      params.append("userId", UserService.getUserId());
      await axios
      .post(API_CLIENT_URL + "/get-symbol-type-options", params, { headers: await authHeader() })
      .then(response => callback(response))
      .catch(error => {
          AlertService.closeAlert();
          console.log("Error message from server: " + error.response.data.message); // eslint-disable-line no-console
          error.message += ErrorService.mergeError(error.response);
          error.message += app.surveyTakerMessage;
          ErrorService.processError("SymbolService.getSymbolTypeOptions", error, "survey-taker", app);
      });          
    }
    getXSymbol(){
      return X_SYMBOL;
    }
    getBarSymbol(){
      return BAR_SYMBOL;
    }
}

export default new SymbolService();
