<template>
  <div class="w-75 border-bottom pt-1 pb-3">
    <div class="row pt-3">
      <h4>Import Type</h4>
    </div>
    <div class="row">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";

export default {
  name: "ImportType",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "ImportType",
      table: TableService.getImportTypeAttributes()
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      AdminService.getImportType(this.getImportTypeCallback, this);
    },
    getImportTypeCallback(response) {
      this.$root.processServerResponse();
      this.table.data = response.data.data;
      this.$parent.importTypeList = response.data.data;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      AlertService.closeAlert();
      this.buildResponsiveTable();
    },
    loadData() {
      this.getTableData();
      this.table.configuration.order = [[1, "desc"]];
      this.table.configuration.dom = "frtpB";
      this.table.configuration.responsive = false;
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.loadData();
    }
  },
  props: {},
  mounted() {}
};
</script>
