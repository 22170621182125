<template>
  <div v-if="displayDetails" class="col">
    <question-details
      :question-index="questionIndex"
      :section-object="sectionObject"
      :question-object="questionObject"
      :widget-type-options="widgetTypeOptions"
      :disable-edit-mode="disableEditMode"
      :display-required="displayRequired"
      :display-include-as-segment="displayIncludeAsSegment"
      @remove="removeQuestion"
    ></question-details>
  </div>
  <div v-else-if="isQuestionInEmotionalSection" class="form-group form-inline no-gutters">
    <label :for="questionId" class="col-3 pr-3">
      <modal-link v-if="displayModal" modal-id="Emotional Needs"  :content-list="$parent.$parent.pageContent"></modal-link>
      {{ getDriverName(questionObject.name) }}
    </label>
    <input v-model="questionObject.label[0]" :id="questionId" :name="questionId" type="text" class="form-control col-3 p-1" @blur="saveQuestionLabelEvent($event)" />
    <span class="text-center col-1">vs.</span>
    <input v-model="questionObject.label[1]" :id="questionId" :name="questionId" type="text" class="form-control col-3 p-1" @blur="saveQuestionLabelEvent($event)" />
    <div class="col-1 pl-1">
      <a v-if="displayAddNeedQuestion" href="#!" @click="this.addNeedQuestion" class="btn-md">
        <font-awesome-icon :icon="['fa', 'plus-circle']" />
      </a>
      <a v-if="displayRemoveNeedQuestion" href="#!" @click="this.removeNeedQuestion" class="pl-2 btn-md">
        <font-awesome-icon :icon="['fa', 'minus-circle']" />
      </a>
    </div>
  </div>
  <div v-else class="form-group form-inline no-gutters">
    <label :for="questionId" class="col-5 pr-2">
      <modal-link v-if="displayModal" modal-id="Functional Needs"  :content-list="$parent.$parent.pageContent"></modal-link>
      {{ displayQuestionName }}
    </label>
    <div v-show="isFunctionalNeed" class="col-2 pr-2">
      <survey-field
        :display-label="false"
        :survey-id="this.$parent.surveyId"
        :survey-field-object="this.$parent.$parent.getSurveyField('functional-need-driver-' + surveyIndex)"
        field-class="custom-select w-100 p-1"
        field-type="dropdown"
        label-class="section-label"
        :field-options="this.$parent.driverOptions"
        :default-field-name="'functional-need-driver-' + surveyIndex"
        default-field-value="1"
        @save="this.$parent.$parent.saveSurveyField"
      ></survey-field>
    </div>
    <input v-model="questionLabel" :id="questionId" :name="questionId" type="text" class="form-control col-3 p-1" style="width: 100%" @blur="saveQuestionLabelEvent($event)" />
    <div class="col-2 pl-2">
      <a v-if="displayAddNeedQuestion" href="#!" @click="this.addNeedQuestion" class="pl-2 btn-md">
        <font-awesome-icon :icon="['fa', 'plus-circle']" />
      </a>
      <a v-if="displayRemoveNeedQuestion" href="#!" @click="this.removeNeedQuestion" class="pl-2 btn-md">
        <font-awesome-icon :icon="['fa', 'minus-circle']" />
      </a>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import ContentMixins from "../mixins/ContentMixins.vue";
import QuestionMixins from "../mixins/QuestionMixins.vue";
import QuestionDetails from "../components/QuestionDetails.vue";
import authHeader from "../services/auth-header";
import AdminService from "../services/admin.service";
import ErrorService from "../services/error.service";
import QuestionService from "../services/question.service";
import NeedsService from "../services/needs.service";
import SurveyField from "../components/SurveyField.vue";

export default {
  name: "Question",
  mixins: [ContentMixins, QuestionMixins],
  components: {
    QuestionDetails,
    SurveyField
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    getDriverName: function(name){
      if (name == "Emotional 1"){
        return "Trust";
      } else if (name == "Emotional 2"){
        return "Strictness";
      } else if (name == "Emotional 3"){
        return "Activity";
      } else if (name == "Emotional 4"){
        return "Curiosity";
      } else if (name == "Emotional 5"){
        return "Assurance";
      }
      return "";
    },
    addNeedQuestion: function() {
      let sequenceNumber = this.nextSequenceNumber;
      let sequenceNumberName = this.nextSequenceNumber + 1;
      let baseName = this.isQuestionInEmotionalSection ? "Emotional " : "Functional ";
      let label = this.isQuestionInEmotionalSection ? "Left Label, Right Label" : baseName + "Need " + sequenceNumberName;
      let questionTypeName = this.isQuestionInEmotionalSection ? "emotional" : "functional";
      var newQuestion = {
        name: baseName + sequenceNumberName,
        surveyId: this.$parent.surveyId,
        sectionId: this.sectionObject.sectionId,
        label: label,
        required: "N",
        display: "Y",
        includeAsSegment: "N",
        questionTypeName: questionTypeName,
        widgetTypeId: this.questionObject.widgetType.widgetTypeId,
        sequenceNumber: sequenceNumber,
        widgetConfiguration: this.questionObject.widgetConfiguration
      };
      this.$root.processServerRequest("Adding question");
      QuestionService.addQuestion(newQuestion, this.addNeedQuestionCallback, this);
    },
    addNeedQuestionCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$parent.setSurveyToBuildRequired();
        this.$parent.$data.questions.push(response.data.data);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    removeNeedQuestion: function() {
      this.$root.processServerRequest("Deleting Question");
      if (!this.initialLoad) {
        QuestionService.deleteQuestion(this.questionObject.questionId, this.removeNeedQuestionCallback, this);
      }
    },
    removeNeedQuestionCallback(response) {
      this.$root.processServerResponse();
      let questionList = this.$parent.$data.questions;
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$parent.setSurveyToBuildRequired();
        var match = questionList.find(x => Number(x.questionId) === Number(this.questionObject.questionId));
        if (typeof match !== "undefined") {
          questionList.splice($.inArray(match, questionList), 1);
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    removeQuestion: function(result) {
      result.active = "N";
      this.$parent.setTotalNumberOfChartSegments();
    },
    saveQuestionLabelEvent: function(event) {
      this.saveQuestionLabel(event.target.value);
    },
    async saveQuestionLabel(newValue) {
      if (this.questionObject.label != newValue) {
        if (this.isQuestionInEmotionalSection) {
          this.questionLabel = this.questionObject.label.toString();
        } else {
          this.questionLabel = newValue;
        }
        var result = {
          questionId: this.questionObject.questionId,
          label: this.questionLabel
        };
        this.$parent.setSurveyToBuildRequired();
        if (!this.initialLoad) {
          QuestionService.saveQuestionLabel(result, this);
        }
      }
    }
  },
  created() {
    if (this.isQuestionInEmotionalSection) {
      this.questionObject.label = this.questionObject.label.split(",");
    }
  },
  mounted() {
    this.initialLoad = false;
  },
  watch: {},
  computed: {
    displayQuestionName: function(){
      if (this.sectionObject.sectionType.name == "Functional Needs"){
        return NeedsService.getFunctionalNeedsDisplayName(this.questionObject);
      }
      return this.questionName;
    },
    surveyIndex: function() {
      if (this.isFunctionalNeed) {
        return this.questionObject.sequenceNumber + 1;
      }
      return 0;
    },
    isFunctionalNeed: function() {
      return this.sectionTypeName == "Functional Needs";
    },
    displayModal: function() {
      return this.questionIndex == 0;
    },
    numberOfBaseNeeds: function() {
      return this.isQuestionInEmotionalSection ? 5 : 12;
    },
    displayAddNeedQuestion: function() {
      if (this.sectionTypeName == "Emotional Needs") {
        return false;
      }
      let currentNeedsSize = this.$parent.questions.length;
      if (this.questionIndex == currentNeedsSize - 1) {
        return true;
      } else {
        return false;
      }
    },
    displayRemoveNeedQuestion: function() {
      if (this.sectionTypeName == "Emotional Needs") {
        return false;
      }
      return this.questionIndex >= this.numberOfBaseNeeds;
    }
  }
};
</script>
