<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title  d-none">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <svg width="200" height="20" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="10" x2="50" y2="10" stroke="black" stroke-width="10"/>
      </svg>
      <br>      
      <svg id="mySvg" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        <line x1="10" y1="10" x2="90" y2="90" stroke="red" stroke-width="20" />
        <line x1="90" y1="10" x2="10" y2="90" stroke="red" stroke-width="20" />
      </svg>
    </div>
    <div class="row pt-3">
      <button type="button" class="btn btn-primary" @click="generatePath">
              <span class="align-text-bottom">Generate Path</span>
      </button>      
    </div>
    <div class="row pt-3">
      Generated Symbol
    </div>
    <div class="row pt-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        <path d="path://M10 10 L90 10 L90 90 L10 90 Z" stroke="black" stroke-width="10" fill="none" />
      </svg>         
    </div>
    <div class="row pt-3">
      <p id="pathOutput"></p>
    </div>

    <div class="row pt-3">
      <div class="col-4"> <h4>Load Chart Image</h4> </div>
    </div>
    <div class="row pt-3">
        <a class="pr-2" href="#" @click="showImportModal">
            <button type="button" class="btn btn-primary">
              <span class="align-text-bottom">Upload Valid Options <font-awesome-icon :icon="['fas', 'upload']"/></span>
            </button>
        </a>    
    </div>
    <div class="row pt-3">
      <div class="col-4"> <h4>Generate Report</h4> </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="generateReport">Generate Report</button>
         </div>  
      </div>
    </div>
    <div id="cp-by-functional-need-e-chart" class="chart-row chart-wrapper" style="">
      <div class="e-chart-container" style="width:1075px">
        <div class="title-and-chart" style="width:1075px">      
          <div :id="eChartId" class="e-chart" style="width:1075px">
            <chart :options="chart.options"></chart>
          </div>
        </div>
      </div>
    </div>
    <import-valid-options :question-id="questionId" />              
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import ReportService from "../services/report.service";
import UserService from "../services/user.service";
import ClientService from "../services/client.service";
import SymbolService from "../services/symbol.service";
import axios from "axios";
import authHeader from "../services/auth-header";
import EChartsMixins from "../mixins/EChartsMixins.vue";
import $ from "jquery";
import { markRaw } from 'vue'; 
import Echarts from "vue-echarts";
import echarts from "echarts";

import ImportValidOptions from "../modals/ImportValidOptions.vue";


export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins, DataTableMixins,EChartsMixins],
 components: {ImportValidOptions},
  props: {},
  data() {
    return {
      title: "At the Playground",
      eChartId: "profile-chart",
      disableButton: false,
      modalLoading: false,
      surveyId: SurveyService.getSurveyId(),
      questionId: 238137,
      clientTimePeriodId: 11155,
      clientReport: {"id":0,"clientReportId":0,"clientId":38175,"title":"Test for Widescreen","subTitle":"Roadrunner","filePath":"Test for Widescreen-Roadrunner.pptx","includeCoverPage":"Y","includeContentPage":"Y","includeEndPage":"Y","pin":"N","clientName":"RoadRunner","dateCreated":"2024-11-06T07:07:01.000+00:00","dateModified":"2024-11-06T07:07:01.000+00:00","clientChartReportPages":[{"clientChartId":1219,"name":"Chart - Needs Profile","include":true},{"clientChartId":1219,"name":"Table - Functional Needs","include":true},{"clientChartId":1219,"name":"Table - Emotional Needs","include":true},{"clientChartId":1220,"name":"Chart - Moat Profile","include":true},{"clientChartId":1220,"name":"Table - Moat Profile","include":true}],"savedClientChartReportPages":[{"id":0,"reportPageTypeId":4,"clientChartId":1219,"sequenceNumber":null,"name":"Chart - Needs Profile","include":true},{"id":0,"reportPageTypeId":5,"clientChartId":1219,"sequenceNumber":null,"name":"Table - Functional Needs","include":true},{"id":0,"reportPageTypeId":7,"clientChartId":1219,"sequenceNumber":null,"name":"Table - Emotional Needs","include":true},{"id":0,"reportPageTypeId":9,"clientChartId":1220,"sequenceNumber":null,"name":"Chart - Moat Profile","include":true},{"id":0,"reportPageTypeId":10,"clientChartId":1220,"sequenceNumber":null,"name":"Table - Moat Profile","include":true}]},
      refreshModal: true ,
      
      path: "",
    };
  },
  methods: {

    generatePath(){
      const svgElement = document.getElementById('mySvg');
      const bbox = svgElement.getBBox();
      let pathData = `
        M${bbox.x} ${bbox.y}
        L${bbox.x + bbox.width} ${bbox.y}
        L${bbox.x + bbox.width} ${bbox.y + bbox.height}
        L${bbox.x} ${bbox.y + bbox.height}
        Z
      `;
      this.path = pathData;
      pathData = `path://${pathData.trim()}`;
      document.getElementById('pathOutput').textContent = pathData;    
    },
/**** Functions for Symbol Assignment - Start */    
    showImportModal(){
        this.returnMessage = "";
        this.$modal.show('import-valid-options');
    },        
    getTableData() {
      
    },
/**** Functions for Symbol Assignment - End */    

/**** Functions for TypeAhead Assignment - Start */    
    onBlurTypeahead(event) {
      
    },

/**** Functions for TypeAhead Assignment - End */    

/**** Functions for Generate Report - Start*/    
    generateReport(){
      ReportService.generatePowerPointReport(this.clientReport, false, this.downloadItemCallback, this);
    },
    getDownloadFilename() {
      let filename = this.clientReport.title;
      if (this.clientReport.subTitle != "") {
        filename += "-" + this.clientReport.subTitle;
      }
      filename += ".pptx";
      return filename;
    },    
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data], { type: "application/pdf" });
      this.clickLink(blob);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete", duration: 2000 });
      this.$root.processServerRequest("Reloading generated reports and resetting for a new report");      
    },    
/**** Functions for Generate Report - End*/        
  },
  created() {},
  mounted() {
    SymbolService.getSymbolTypeOptions(this.getSymbolTypeOptionsCallback, this);
    if (typeof this.clientObject.symbolTypeId !== "undefined") {
      this.symbolTypeId = this.clientObject.symbolTypeId;
    }

  }
};
</script>
<style lang="scss">
</style>
