<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
            <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <company-menu></company-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Select the Organization from the top-left menu in the wRatings System to control the chart colors for all surveys in that Organization.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Enter the hex code for a color or click the color to modify any color. The system saves color changes automatically. Click the Reset button to revert all changes for that Area back to the default settings.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Chart Core colors control how the Expectations bars and Company X’s appear in all charts. The system defaults Expectations to green to represent where growth can be achieved. To change colors for Competitors, contact your wRatings Advisor.

          </div>
        </div>
        <div class="w-100 d-flex  pt-2">
          <div class="circle">4</div>
          <div class="wizard-text">
            Pricing Power represents how much more respondents are willing to pay if Expectations are met. 
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">5</div>  
          <div class="wizard-text">
            Benchmark Companies appear on all charts in the order you select them. To standardize benchmark colors, select them in identical order.
          </div>      
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">6</div>  
          <div class="wizard-text">
            They system defaults colors for Match Profiles by Time Period so that previous survey waves are lighter to fade into the background. Darker colors represent more current survey waves.
          </div>      
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">7</div>  
          <div class="wizard-text">
            Set colors for Match by Saved Card or Survey Groups to compare segments or groups to each other.
          </div>      
        </div>
      </div>
    </div>
    <div v-if="getDisplayView()" class="client-settings">
      <div class="row">
        <div class="settings-section-column col-5">
          <div class="settings-for-organization"> 
            <div class="settings-for-organization-header">Settings For:</div>
            <div class="settings-organization">{{ clientName }}</div>
          </div>
          <div class="settings-section">
            <div class="settings-section-header">
              <h4 class="card-title text-primary">Chart Core</h4>
              <button type="submit" class="btn btn-primary" @click="resetChartCore" :disabled="isSavingColor">Reset</button>
            </div>
            <div class="settings-sub-section">
              <div class="col-12 no-gutters pl-0 pr-0">            
                <div class="form-group form-inline mb-0">            
                  <label for="symbolTypeId" class="col-3 pl-0">Marker Type</label>
                  <select
                    v-model="symbolTypeId"
                    class=" form-control"
                    id="symbolTypeId"
                    name="symbolTypeId"
                    @blur="saveSymbolType($event)"
                  >
                    <option v-for="(dropdownOption, idxDropdown) in this.symbolTypeOptions" :selected="dropdownOption.selected" :value="dropdownOption.value" v-bind:key="idxDropdown">
                      <span>{{ dropdownOption.name }} </span>
                    </option>
                  </select>
                  <span class="pl-3" v-html="svgText"></span>
                </div>
                <div v-for="(chartCoreSetting, idxChartCore) in coreColors" v-bind:key="idxChartCore" class="form-group form-inline mb-0">
                  <label :for="chartCoreSetting.displayName" class="col-3 pl-0">{{ chartCoreSetting.displayName }}</label>
                  <input
                    type="text"
                    :disabled="isSavingColor"
                    class="form-control"
                    :value="chartCoreSetting.clientSettingValue"
                    @blur="saveColorEvent(chartCoreSetting.clientSettingTypeName, chartCoreSetting.clientSettingValue, $event)"
                  />
                  <button
                    class="fg-color-sample"
                    :disabled="isSavingColor"
                    @click="pickColorForClientSetting(chartCoreSetting.clientSettingTypeName, chartCoreSetting.clientSettingValue)"
                    :style="{ background: chartCoreSetting.clientSettingValue }"
                  ></button>
                </div>
              </div>  
            </div>  
          </div>
          <div class="settings-message"> 
            Colors are set at an organization level. To change colors for a different organization, select a new organization in the top-left menu at top of page.
          </div>
          <div class="settings-section">
            <div class="settings-section-header">
              <h4 class="card-title text-primary">Match by Time Period</h4>
              <button type="submit" class="btn btn-primary" @click="resetSection('match-by-time-period')" :disabled="isSavingColor">Reset</button>
            </div>
            <div class="settings-sub-section">
              <div class="col-12 no-gutters">
                <div class="settings-sub-section-header">
                  <div class="col-3">&nbsp;</div>
                  <div>Company</div>
                </div>
                <div
                  v-for="(matchByTimePeriodCompanySetting, idxMatchByTimePeriodCompany) in matchByTimePeriodCompanyColors"
                  v-bind:key="idxMatchByTimePeriodCompany"
                  class="form-group form-inline mb-0"
                >
                  <label :for="matchByTimePeriodCompanySetting.displayName" class="col-3">{{ matchByTimePeriodCompanySetting.displayName }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="isSavingColor"
                    :value="matchByTimePeriodCompanySetting.clientSettingValue"
                    @blur="saveColorEvent(matchByTimePeriodCompanySetting.clientSettingTypeName, matchByTimePeriodCompanySetting.clientSettingValue, $event, matchByTimePeriodCompanySetting.sequenceNumber)"
                  />
                  <button
                    class="fg-color-sample"
                    :disabled="isSavingColor"
                    @click="pickColorForClientSetting(matchByTimePeriodCompanySetting.clientSettingTypeName, matchByTimePeriodCompanySetting.clientSettingValue, matchByTimePeriodCompanySetting.sequenceNumber)"
                    :style="{ background: matchByTimePeriodCompanySetting.clientSettingValue }"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="settings-section">
            <div class="settings-section-header">
              <h4 class="card-title text-primary">Match by Saved Card</h4>
              <button type="submit" class="btn btn-primary" @click="resetSection('match-by-saved-card')" :disabled="isSavingColor">Reset</button>
            </div>
            <div class="settings-sub-section">
              <div class="col-12 no-gutters">
                <div class="settings-sub-section-header">
                  <div class="col-3">&nbsp;</div>
                  <div>Company</div>
                </div>
                <div
                  v-for="(matchBySavedCardCompanySetting, idxMatchBySavedCardCompany) in matchBySavedCardCompanyColors"
                  v-bind:key="idxMatchBySavedCardCompany"
                  class="form-group form-inline mb-0"
                >
                  <label :for="matchBySavedCardCompanySetting.displayName" class="col-3">{{ matchBySavedCardCompanySetting.displayName }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="isSavingColor"
                    :value="matchBySavedCardCompanySetting.clientSettingValue"
                    @blur="saveColorEvent(matchBySavedCardCompanySetting.clientSettingTypeName, matchBySavedCardCompanySetting.clientSettingValue, $event, matchBySavedCardCompanySetting.sequenceNumber)"
                  />
                  <button
                    class="fg-color-sample"
                    :disabled="isSavingColor"
                    @click="pickColorForClientSetting(matchBySavedCardCompanySetting.clientSettingTypeName, matchBySavedCardCompanySetting.clientSettingValue, matchBySavedCardCompanySetting.sequenceNumber)"
                    :style="{ background: matchBySavedCardCompanySetting.clientSettingValue }"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="settings-section">
            <div class="settings-section-header">
              <h4 class="card-title text-primary">Match by Survey Group and Survey</h4>
              <button type="submit" class="btn btn-primary" @click="resetSection('match-by-survey')" :disabled="isSavingColor">Reset</button>
            </div>
            <div class="settings-sub-section">
              <div class="col-12 no-gutters">
                <div class="settings-sub-section-header">
                  <div class="col-3">&nbsp;</div>
                  <div>Company</div>
                </div>
                <div
                  v-for="(matchBySurveyCompanySetting, idxMatchBySurveyCompany) in matchBySurveyCompanyColors"
                  v-bind:key="idxMatchBySurveyCompany"
                  class="form-group form-inline mb-0"
                >
                  <label :for="matchBySurveyCompanySetting.displayName" class="col-3">{{ matchBySurveyCompanySetting.displayName }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="isSavingColor"
                    :value="matchBySurveyCompanySetting.clientSettingValue"
                    @blur="saveColorEvent(matchBySurveyCompanySetting.clientSettingTypeName, matchBySurveyCompanySetting.clientSettingValue, $event, matchBySurveyCompanySetting.sequenceNumber)"
                  />
                  <button
                    class="fg-color-sample"
                    :disabled="isSavingColor"
                    @click="pickColorForClientSetting(matchBySurveyCompanySetting.clientSettingTypeName, matchBySurveyCompanySetting.clientSettingValue, matchBySurveyCompanySetting.sequenceNumber)"
                    :style="{ background: matchBySurveyCompanySetting.clientSettingValue }"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <color-picker ref="colorPickerForClientSetting" modal-name="main-client-setting-color-picker" @save="updateClientSetting" />
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
  </div>
</template>
<script>
import CompanyMixins from "../../mixins/CompanyMixins.vue";
import ContentMixins from "../../mixins/ContentMixins.vue";
import CompanyMenu from "../../views/menu/CompanyMenu.vue";
import Modal from "../../components/Modal.vue";
import ModalLink from "../../components/ModalLink.vue";
import AdminService from "../../services/admin.service";
import ClientService from "../../services/client.service";
import ContentService from "../../services/content.service";
import ClientSettingsService from "../../services/client.settings.service";
import ColorService from "../../services/color.service";
import SymbolService from "../../services/symbol.service";
import ColorPicker from "../../modals/ColorPicker.vue";

export default {
  name: "ClientSettings",
  mixins: [CompanyMixins, ContentMixins],
  components: {
    Modal,
    ModalLink,
    CompanyMenu,
    ColorPicker,
  },
  props: {},
  data() {
    return {
      pageNumber: 2,
      title: "Settings",
      isSavingColor: false,
      tooltipConfigShow: ContentService.getWizardTooltip(),     
      settings: [],
      settingValue: "",
      settingType: "",
      settingSequenceNumber: -1,
      coreColors: [],
      w50Colors: [],
      matchByTimePeriodExpectationsColors: [],
      matchByTimePeriodCompanyColors: [],
      matchBySavedCardExpectationsColors: [],
      matchBySavedCardCompanyColors: [],
      matchBySurveyExpectationsColors: [],
      matchBySurveyCompanyColors: [],
      symbolTypeOptions: [],
      symbolTypeId: 0,
    };
  },
  methods: {

    getSymbolTypeOptionsCallback(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.symbolTypeOptions = response.data.data.symbolTypeOptions;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }    
    },    
    saveSymbolType: function(event) {
      let newValue = event.target.value;
      ClientService.saveClientSymbol(this.clientCode, newValue, this.saveClientSymbolCallback, this);
    },    
    saveClientSymbolCallback(response) {
      this.isSavingColor = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let returnClient = JSON.parse(response.data.data);
        this.clientObject.symbolTypeId = returnClient.symbolTypeId;        
        ClientService.saveClient(this.clientObject);
        this.$root.setClientObject(this.clientObject);
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    async resetChartCore() {
      this.$root.processServerRequest("Resetting colors");
      ClientSettingsService.resetClientSettings(this.clientCode, ColorService.DEFAULT_CORE_COLORS, this.processClientSettings);
      let defaultClientColor = ColorService.getValue("company-color", ColorService.DEFAULT_CORE_COLORS);
      ClientService.saveClientColor(this.clientCode, defaultClientColor, this.saveClientColorCallback, this);
    },
    async resetSection(type) {
      this.$root.processServerRequest("Resetting colors");
      if (type == "w-30") {
        await ClientSettingsService.resetClientSettings(this.clientCode, ColorService.DEFAULT_W50_COLORS, this.processClientSettings);
      } else if (type == "match-by-saved-card") {
        let settings = [].concat(ColorService.DEFAULT_MATCH_BY_SAVED_CARD_EXPECTATIONS_COLORS, ColorService.DEFAULT_MATCH_BY_SAVED_CARD_COMPANY_COLORS);
        await ClientSettingsService.resetClientSettings(this.clientCode, settings, this.processClientSettings);
      } else if (type == "match-by-time-period") {
        let settings = [].concat(ColorService.DEFAULT_MATCH_BY_TIME_PERIOD_EXPECTATIONS_COLORS, ColorService.DEFAULT_MATCH_BY_TIME_PERIOD_COMPANY_COLORS);
        await ClientSettingsService.resetClientSettings(this.clientCode, settings, this.processClientSettings);
      } else if (type == "match-by-survey") {
        let settings = [].concat(ColorService.DEFAULT_MATCH_BY_SURVEY_EXPECTATIONS_COLORS, ColorService.DEFAULT_MATCH_BY_SURVEY_COMPANY_COLORS);
        await ClientSettingsService.resetClientSettings(this.clientCode, settings, this.processClientSettings);
      }
    },
    saveColorEvent(type, originalValue, event, sequenceNumber = 1){
      if (originalValue !== event.target.value){
        this.settingType = type;
        this.settingSequenceNumber = sequenceNumber;        
        this.updateClientSetting(event.target.value);
      }
    },    
    pickColorForClientSetting(type, value, sequenceNumber = 1) {
      this.settingType = type;
      this.settingValue = value;
      this.settingSequenceNumber = sequenceNumber;
      this.$refs.colorPickerForClientSetting.$data.currentColor = {};
      this.$refs.colorPickerForClientSetting.$data.currentColor.hex = this.settingValue;
      this.$refs.colorPickerForClientSetting.$data.currentColor.hex8 = this.settingValue;
      this.$modal.show("main-client-setting-color-picker");
    },
    updateClientSetting(result) {
      this.settingValue = result;
      this.saveClientSetting();
    },
    async saveClientSetting() {
      this.$root.processServerRequest("Saving company setting");
      this.isSavingColor = true;
      if (this.settingType === "company-color") {
        ClientService.saveClientColor(this.clientCode, this.settingValue, this.saveClientColorCallback, this);
      } else {
        ClientSettingsService.saveClientSetting(this.clientCode, this.settingType, this.settingValue, this.settingSequenceNumber,this.saveClientSettingCallback, this);
      }
    },
    saveSettingValues() {
      ColorService.setValue(this.settingType, this.settingValue, this.coreColors);
      ColorService.setValue(this.settingType, this.settingValue, this.w50Colors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchByTimePeriodExpectationsColors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchByTimePeriodCompanyColors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchBySavedCardExpectationsColors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchBySavedCardCompanyColors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchBySurveyExpectationsColors);
      ColorService.setValue(this.settingType, this.settingValue, this.matchBySurveyCompanyColors);
    },
    saveClientSettingCallback(response) {
      this.isSavingColor = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.saveSettingValues();
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveClientColorCallback(response) {
      this.isSavingColor = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let returnClient = JSON.parse(response.data.data);
        this.clientObject.color = returnClient.color;        
        ClientService.saveClient(this.clientObject);
        this.$root.setClientObject(this.clientObject);
        var coreSetting = this.coreColors.find((x) => x.clientSettingTypeName == "company-color");
        if (typeof coreSetting != "undefined") {
          coreSetting.clientSettingValue = this.clientObject.color;
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    processClientSettings(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.settings = response.data.data;
        this.w50Colors = ColorService.setColors(this.settings, ColorService.DEFAULT_W50_COLORS);
        this.coreColors = ColorService.setColors(this.settings, ColorService.DEFAULT_CORE_COLORS);
        this.matchByTimePeriodExpectationsColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_TIME_PERIOD_EXPECTATIONS_COLORS);
        this.matchByTimePeriodCompanyColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_TIME_PERIOD_COMPANY_COLORS);
        this.matchBySavedCardExpectationsColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_SAVED_CARD_EXPECTATIONS_COLORS);
        this.matchBySavedCardCompanyColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_SAVED_CARD_COMPANY_COLORS);
        this.matchBySurveyExpectationsColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_SURVEY_EXPECTATIONS_COLORS);
        this.matchBySurveyCompanyColors = ColorService.setColors(this.settings, ColorService.DEFAULT_MATCH_BY_SURVEY_COMPANY_COLORS);

        var coreSetting = this.coreColors.find((x) => x.clientSettingTypeName == "company-color");
        if (typeof coreSetting != "undefined") {
          coreSetting.clientSettingValue = this.clientObject.color;
        }
        this.setDisplayView(true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadData: function () {
      this.$root.processServerRequest("Loading settings");
      ClientSettingsService.getClientSettings(this.clientCode, this.processClientSettings, this);
      SymbolService.getSymbolTypeOptions(this.getSymbolTypeOptionsCallback, this);
      if (typeof this.clientObject.symbolTypeId !== "undefined") {
        this.symbolTypeId = this.clientObject.symbolTypeId;
      }
    },
  },
  created() {},
  mounted() {},
  computed: {
    svgText: function () {
      if (this.symbolTypeId == 1) { // Circle
        return '<svg width="20" height="20"><circle cx="10" cy="10" r="10" fill="' + this.clientObject.color + '" /></svg>';
      } else if (this.symbolTypeId == 2) {  // Rectangle
        return '<svg width="20" height="20"><rect x="0" y="0" width="20" height="20" fill="' + this.clientObject.color + '" /></svg>';
      } else if (this.symbolTypeId == 3) { // Round Rectangle
        return '<svg fill="' + this.clientObject.color + '" width="20" height="20" viewBox="0 0 50 50" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" overflow="inherit"><path d="M49 41c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8v-32c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v32z"/></svg>';        
      } else if (this.symbolTypeId == 4) { // Diamond
        return '<svg width="20" height="20"><polygon points="10,0 20,10 10,20 0,10" fill="' + this.clientObject.color + '" /></svg>';
      } else if (this.symbolTypeId == 5) { // Pin
        return '<svg width="20" height="20" viewBox="0 0 16 16" fill="' + this.clientObject.color + '" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M2 6V6.29266C2 7.72154 2.4863 9.10788 3.37892 10.2236L8 16L12.6211 10.2236C13.5137 9.10788 14 7.72154 14 6.29266V6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6ZM8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" fill="' + this.clientObject.color + '" fill-rule="evenodd"/></svg>';
      } else if (this.symbolTypeId == 6) { // Arrow
        return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 237.64 237.64" width="20" height="20" style="enable-background:new 0 0 237.64 237.64;" xml:space="preserve"><g><path style="fill:' + this.clientObject.color + ';" d="M7.954,226.53c-2.23,4.623-2.295,8.072-0.609,9.915c3.911,4.275,15.926-3.905,23.323-9.051   l58.416-40.662c7.397-5.145,20.402-11.835,29.414-11.993c0.897-0.016,1.8-0.011,2.703,0.011c9.007,0.218,21.958,7.016,29.3,12.238   l56.403,40.151c7.343,5.221,19.303,13.473,23.301,9.219c1.74-1.849,1.751-5.33-0.381-9.997L129.648,7.047   c-4.264-9.333-11.335-9.404-15.79-0.163L7.954,226.53z"/></g></svg>';        
      } else if (this.symbolTypeId == 7) { // X
        return '<svg fill="' + this.clientObject.color + '" width="20" height="20" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><path d="M797.32 985.882 344.772 1438.43l188.561 188.562 452.549-452.549 452.548 452.549 188.562-188.562-452.549-452.548 452.549-452.549-188.562-188.561L985.882 797.32 533.333 344.772 344.772 533.333z"/></svg>';        
      }
      return '';
    },    
  },
};
</script>
