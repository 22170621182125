<template>
  <div></div>
</template>
<script>
import AdminService from "../services/admin.service";
import ContentService from "../services/content.service";
import UserService from "../services/user.service";
import SurveyService from "../services/survey.service";
import ClientService from "../services/client.service";
import ClientTimePeriodService from "../services/client.time.period.service";
import ChartService from "../services/chart.service";
import ReportService from "../services/report.service";
import DashboardCardExpand from "../components/dashboard/DashboardCardExpand.vue";
import SmartCenterLink from "../components/SmartCenterLink.vue";
import DashboardModal from "../modals/DashboardModal.vue";
import GlobalMixins from "./GlobalMixins.vue";

import $ from "jquery";

export default {
  name: "DashboardMixins",
  mixins: [GlobalMixins],
  components: {
    /* eslint-disable vue/no-unused-components */
    SmartCenterLink,
    DashboardCardExpand,
    DashboardModal
  },
  data() {
    return {
      displayDashboard: false,
      pageContentForReport: [],
      reloadData: false,
      minDashboardChartHeight: 250,
      rankCompanyCount: 5,
      options: {},
      rival_set_options: [],
      chart: {
        title: ""
      },
      chartTitle: "",
      chartTypeName: "",
      clientChart: {
        clientChartId: 0
      },      
      containerId: "",
      reportTypeName: "",
      imageUrl: null,
      displayModal: true
    };
  },
  props: {
    inputLoadCompetitors: {
      type: Boolean,
      default: false
    },    
    loadCompetitorData: {
      type: Boolean,
      default: true
    },
    loadContent: {
      type: Boolean,
      default: true
    },
    initialPageContentForReport: {
      type: Array,
      default: function () {
        return [];
      },
    },
    initialCompetitorsData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    timePeriodId: {
      type: String,
      default: ""
    },
    entrySurveyCode: {
      type: String,
      default: ""
    },
    entryClientChart: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dashboardId: {
      type: String,
      default: "expanded-dashboard"
    },
    dashboardLayoutCard: {
      type: Object,
      default: function () {
        return {
          dashboardId: 0
        };
      },
    },    
  },
  created() {
    if (this.loadContent == true) {
      ContentService.getPageContent("Report", this.getPageContentByReportCallBack, this);
    } else {
      this.pageContentForReport = JSON.parse(JSON.stringify(this.initialPageContentForReport));
    }
  },
  methods: {
    getPageContentByReportCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForReport = response.data.data;
      }
    },
    getClass: function (code, sequenceNumber, includeCode) {
      let returnClass = "dashboard-card card ";
      if (code == "") {
        returnClass += "d-none";
      } else {
        if (includeCode == true){
          returnClass += code + "-layout ";
        }
        returnClass += "order-" + sequenceNumber.toString();
      }
      return returnClass;
    },
    getCompetitorSetCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.rival_set_options = [];
        let vm = this;
        $.each(response.data.data, function(i, item) {
          let responseOption = {};
          responseOption.name = item.name;
          responseOption.value = item.value;
          vm.rival_set_options.push(responseOption);
        });
      } else if (statusCode == 201) {
                AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 },true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    printOptions(){
       // eslint-disable-next-line no-console
       console.log(this.dashboardId + " options: " + JSON.stringify(this.options));
    },
    saveImage(){
      if (this.isGenerateReportRunning()){
        let vm = this;
        setTimeout(function() {
          vm.imageUrl =  vm.chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
          });      
        }, 500);  
      }
    },
    findSavedClientChartByDashboardCardIdAndTimePeriod: function() {
      for (let index = 0; index < this.clientObject.clientCharts.length; index++) {
        let dashboardId = this.clientObject.clientCharts[index].dashboardCardId;
        let clientTimePeriodId = this.clientObject.clientCharts[index].clientTimePeriodId;
        let displayType = this.clientObject.clientCharts[index].displayType;
        if (dashboardId == this.dashboardLayoutCard.dashboardCardId &&
            clientTimePeriodId == Number(this.timePeriodId) &&
            displayType == this.chartTypeName){
          return this.clientObject.clientCharts[index];
        }
      }
      return {};
    },
    setupClientChart: function() {
      let clientChartValue = this.findSavedClientChartByDashboardCardIdAndTimePeriod();
      if (!$.isEmptyObject(clientChartValue)) {
          this.clientChart.clientChartId = clientChartValue.clientChartId;
          this.clientChart.name = clientChartValue.name;
      }
      let chartType = ChartService.findChartTypeByName(this.chartTypeName);
      this.clientChart.chartTypeId = chartType.chartTypeId;
      this.clientChart.criteria = this.buildUrlParams().toString();
      this.clientChart.clientTimePeriodId = this.timePeriodId;
      this.clientChart.displayType = this.chartTypeName;
      this.clientChart.dashboardCardId = this.dashboardLayoutCard.dashboardCardId;
    },
    initializeClientChart: function(){
      let chartTitle = ChartService.createDefaultSaveChartName(this.chartTitle, "");
      if (typeof this.$parent.client_time_periods != "undefined"){
        this.clientChart.name = chartTitle + " " + ClientTimePeriodService.getPeriodName(this.$parent.client_time_periods, Number(this.timePeriodId));
      } else {
        this.clientChart.name = chartTitle + " ";
      }
      this.clientChart.clientId = ClientService.getClientId();
    },
    updateClientCharts: function(value, message, modal) {
      this.clientObject.clientCharts = [...value];
      ClientService.saveClient(this.clientObject);
      this.$root.setClientObject(this.clientObject);
      AdminService.displaySuccessMessage({ text: message });
      //this.$modal.hide(this.modalName);
      //this.$modal.hide("modal-save-chart-confirmation");
      this.closeModal(modal);
      this.setupClientChart();
    },
    saveClientChart(value, message) {
      this.clientChart = JSON.parse(JSON.stringify(value));
      this.updateClientCharts(value, message);
    },
    isGenerateReportRunning(){
      return ReportService.getReportAutoChartGenerateStatus() == "generate";
    },    
    async saveReportPage(containerId, reportTypeName) {
      if (this.clientChart.clientChartId != 0   && this.isGenerateReportRunning()) {
        let copyChart = $("#" + containerId).clone();
        copyChart.find(".original-dashboard-display").remove();
        let reportPageChartContent = copyChart
          .wrapAll("<div>")
          .parent()
          .html();
        await ReportService.saveReportPage(reportPageChartContent, reportTypeName, this.clientChart, this.saveReportPageCallback, this);
        await ReportService.saveReportPageFile(this.imageUrl, "png", reportTypeName, this.clientChart, this.saveReportPageCallback, this);        
      }
    },
    processAutoGenerateChart() {
      //console.log("DashboardMixins.processAutoGenerateChart: ");
      if (this.autoGenerateChartRun == true) {
        this.clientChart = JSON.parse(JSON.stringify(this.entryClientChart));
        let criteriaParameters = this.entryClientChart.criteria.split("&");
        for (let criteriaIndex = 0; criteriaIndex < criteriaParameters.length; criteriaIndex++) {
          let criteria = criteriaParameters[criteriaIndex];
          this.processSavedChartCriteria(criteria);
        }
      }
    },
    generateNextClientChartLink() {
      let reportClientCharts = ReportService.getReportClientCharts();
      if (reportClientCharts.length > 0) {
        let clientChart = reportClientCharts[0];
        let link = ReportService.getClientChartLink(clientChart, this);
        window.location.href = link.href;
      } else if (ReportService.getReportAutoChartGenerateStatus() == "generate") {
        ReportService.clearReportAutoChartGenerate();
        window.close();
      } else if (ReportService.getReportAutoChartGenerateStatus() == "preview") {
        ReportService.clearReportAutoChartGenerate();
      }
    },    
    buildBaseUrlParams() {
      let params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("clientTimePeriodId", this.timePeriodId);
      params.append("userId", UserService.getUserId());
      params.append("surveyId", SurveyService.getSurveyId());
      return params;
    },     
    postProcessAutoGeneration(){
      setTimeout(async () => {
        const vm = this;
        await vm.saveReportPage(this.containerId,this.reportTypeName);
        ReportService.removeReportClientChart(this.clientChart);
        vm.generateNextClientChartLink();
      }, 4000);        
    },  
    saveReportPageCallback(response) {},
    parseMetric: function(){
      if (typeof this.metricId != "undefined" && this.metricId.includes("growth")) {
        this.useMetricGrowth = true;
      } 
    },
    resetChart: function(){
      // eslint-disable-next-line no-console
      //console.log("DashboardMixins::resetChart");
      if (this.chart && typeof this.chart.setOption === "function") {
        this.chart.setOption({option: {} }, true);
      }
      if (this.entrySurveyCode == ""){
        this.displayDashboard = true;
      }
    },
    closeModal(name){
      if (this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    }
  },
  watch: {
    initialCompetitorsData: function() {
        this.competitor_options = [...this.initialCompetitorsData];                
    },
    timePeriodId: function() {
      if (this.timePeriodId != "") {
          this.loadData();
          this.initializeClientChart();
      }
    },
    displayDashboard: function(){
      if (this.displayDashboard == true && this.autoGenerateChartRun == true){
        this.postProcessAutoGeneration();
      }    
    }
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardMixins::mounted");
    if (this.autoGenerateChartRun) {
        this.loadData();
        this.initializeClientChart();
    }    
  },
  computed: {
    autoGenerateChartRun: function() {
      //console.log("autoGenerateChartRun.computed  entryClientChart: " + JSON.stringify(this.entryClientChart));
       return !$.isEmptyObject(this.entryClientChart);
    },
    dashboardChartStyle: function() {
      return "height: " + this.minDashboardChartHeight + "px";
    },
  }
};
</script>
