import ScatterService from "./scatter.service";
import CommonService from "./common.service";
import SymbolService from "../symbol.service";
import $, { type } from "jquery";

class ProfileService extends CommonService{
    /*** xAxis Functions ***/

    getXAxisForEmotionalNeeds(){
        let xAxis = this.getBaseXAxis()
        let splitLine = {
            show: false
        };             
        $.extend(true, xAxis.splitLine, splitLine);          
        $.extend(true, xAxis, this.getXAxis(1));          
        return xAxis;
    }
    getXAxisForValueDrivers(){
      let xAxis = [];
      let baseXAxis = {
        type: "value",
        gridIndex: 0,
        axisLabel: {
          show:false,
        },
        axisTick: {
          show:false,
        },
        splitLine: {
          show:false,
        },
        axisLine: {
          show:false,
        },
        min: 0,
        max: 10.5
      };  
      
      let rightAxis = JSON.parse(JSON.stringify(baseXAxis));
      rightAxis.gridIndex = 1;
      let leftAxis = {
        interval: 5.5,
        splitArea:{
          show: true,
          areaStyle: {
            color: ["#e4e6e9","#B8B8B8"],
            opacity: 0.5,
          }
        },      
      }
      $.extend(true, leftAxis, baseXAxis); 
      leftAxis.gridIndex = 0;
      xAxis.push(leftAxis);    
      xAxis.push(rightAxis);
      return xAxis;
    }
    /*** yAxis Functions ***/
       getYAxis(maxPricePower = 5,maxYAxis = 3,displayPricingPower = true,showLabelForGrid1 = true){
        let yAxis = [];
        let yAxisForNeed = this.getYAxisForNeed();
        let yYAxisForPricingPower = this.getYAxisForPricingPower(maxPricePower,showLabelForGrid1);
        yAxis.push(yAxisForNeed);
        if (displayPricingPower == true){
          yAxis.push(yYAxisForPricingPower);
        }
        if (maxYAxis == 2){
          let gridIndex = {
              gridIndex: 1,
          }
          let yAxisForEmotionalNeed = JSON.parse(JSON.stringify(yAxisForNeed));
          let yYAxisForPricingPowerForEmotionalNeed = JSON.parse(JSON.stringify(yYAxisForPricingPower));
          yYAxisForPricingPowerForEmotionalNeed.axisLabel.show = true;
          let yYAxisForPricingPowerEmotionalNeed = JSON.parse(JSON.stringify(yYAxisForPricingPowerForEmotionalNeed));
          // Add for emotional Needs
          $.extend(true, yAxisForEmotionalNeed, gridIndex);
          $.extend(true, yAxisForEmotionalNeed.axisLabel,{show:false});
          $.extend(true, yYAxisForPricingPowerEmotionalNeed, gridIndex);
          $.extend(true, yYAxisForPricingPowerEmotionalNeed.axisLabel,{show:true});
          yAxis.push(yAxisForEmotionalNeed);
          if (displayPricingPower == true){
            yAxis.push(yYAxisForPricingPowerEmotionalNeed);
          }
        }
        return yAxis;
    }    
    getYAxisForValueDrivers(emotionalNames){
      let axisLabel = {
        color: "#787878",
        fontSize: 10,
        fontFamily: "Franklin Gothic Demi Cond",
        //fontWeight: "bold",
        inside: true
      };
      let axisLabelForValueDriver = {
        color: "#000",
        fontSize: 12,
        fontFamily: "Franklin Gothic Demi Cond",
        //fontWeight: "bold",
        inside: true
      };      
      let splitLine = {
        show: true,
        lineStyle: {
          color: "#fff"
        }
      };
      let axisTick = {
        "show": false
      }
      let axisLine = {
        show:false,
      };
      return [{
        type: "category",
        gridIndex: 0,
        data: emotionalNames[0],
        axisLabel: axisLabel,
        splitLine: splitLine,
        axisTick: axisTick,
        axisLine: axisLine
      },
      {
        type: "category",
        gridIndex: 0,
        data: emotionalNames[1],
        axisLabel: axisLabel,        
        splitLine: splitLine,
        axisTick: axisTick,
        axisLine: axisLine
      },
      {
        type: "category",
        gridIndex: 1,
        data: emotionalNames[2],
        axisLabel: axisLabelForValueDriver,        
        splitLine: splitLine,
        axisTick: axisTick,
        axisLine: axisLine
      }];
    }
    /***  Series Functions ***/
    getSeries(name,gridIndex,xAxisIndex,yAxisIndex,config = {},containerId = ""){
        let baseSeries = {
            name: name,
            type: 'scatter',
            gridIndex: gridIndex,
            xAxisIndex: xAxisIndex,
            yAxisIndex: yAxisIndex,
            data: [],
            emphasis: {
              disabled: true,
            },
            animation: false,
        };       
        $.extend(true, baseSeries, config);
        return baseSeries;
    }
    getPricingPowerSeries(gridIndex,xAxisIndex,yAxisIndex,itemColor = "rgb(215, 228, 189)"){
        return this.getMetricSeries("Pricing Power (Bar)",gridIndex,xAxisIndex,yAxisIndex,itemColor);
    }
    /*** Header Functions ***/
    getValueDriverHeaderSeries(gridIndex = 0, xAxisIndex = 0, yAxisIndex = 0){
      let defaultRich = {
          a: {
            align: "left",
            fontSize: 11,
            fontFamily: "Franklin Gothic Demi Cond",
           // fontWeight: "bold",
            lineHeight: 30,
            color: "#fff",
          }
      };
      let base =  {
        name: "Header",
        type: "scatter",
        label: {
          show: false,
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        gridIndex: 1,
      }
      let valueDriverSeries = {
        markArea: {
          label: {
            backgroundColor: "#2F5EB3",
            padding: [0,5,5,5],
            height: 24,
            distance: 0
          },
          data: [
            [
              {
                name: "Value Driver",
                itemStyle: {
                  color: "none"
                },
                label: {
                  width: 205,
                  rich: defaultRich,
                  formatter: "{a|Value Driver}",
                  offset: [-111, 0]
                  
                }
              },
              {
                "xAxis": "0"
              }
            ],
          ]
        }
      };
      let emotionalNeedRich = JSON.parse(JSON.stringify(defaultRich));
      emotionalNeedRich.a.align = "center";
      $.extend(true, defaultRich, emotionalNeedRich);      
      let emotionalNeedSeries = {
        markArea: {
          label: {
            backgroundColor: "#2F5EB3",
            padding: [0,0,5,5],
            height: 24,
            distance: 0
          },
          data: [
            [
              {
                name: "Emotional Need",
                itemStyle: {
                  color: "none"
                },
                label: {
                  width: 437,
                  //offset: [9,0],
                  padding: [0,0,5,35],
                  rich: emotionalNeedRich,
                  formatter: "{a|Emotional Need}",
                  "xAxis": "1"
                }
              },
              {
                "xAxis": "10"
              }
            ],
          ]
        }

      };
      $.extend(true, valueDriverSeries, base);
      $.extend(true, emotionalNeedSeries, base);
      let returnSeries = [];
      returnSeries.push(valueDriverSeries);
      returnSeries.push(emotionalNeedSeries);
      return returnSeries;
    }
    getBaseNeedsHeaderSeries(names = [], breakPointList = [], numberOfNeeds = 14, gridIndex = 0, xAxisIndex = 1, yAxisIndex = 0, defaultWidth = 0,includeMarkLine = false){
      let finalData = [];
      let base =  {           
        name: '',
        itemStyle: {  
          color: '',
        },
        label: {
          width: defaultWidth,
          overflow: 'overflow',
          ellipsis: '...',                    
          rich: {
            a : {
              align: 'center',
              fontSize: 12,
              fontFamily: "Franklin Gothic Medium Cond",                    
           //   fontWeight: "bold",
              lineHeight: 35,
              color: '#fff',
              verticalAlign: 'middle',
            }
          }
        }
      };
      let colorList = [];
      for (let i = 0; i < names.length; i++) {
        colorList.push('none')
      }
      let startList = [''];
      let endList = [];
      startList.push(...breakPointList.map(x => String(x+2)));
      endList = breakPointList.map(x => String(x + 1));
      endList.push(String(numberOfNeeds));
      for (let i = 0; i < names.length; i++) {
        let newCoord = JSON.parse(JSON.stringify(base));
        let xCood = []
        // 1st Coord
        $.extend(true, newCoord.itemStyle, {color: colorList[i]});
        $.extend(true, newCoord, {name: names[i]});
        $.extend(true, newCoord.label, {formatter: '{a|'+names[i]+'}'});
        xCood.push(newCoord);
        if (i > 0) {
          newCoord.xAxis = startList[i]
        } 
        // 2nd Coord
        xCood.push({xAxis: endList[i]});
        finalData.push(xCood);

      }
      let series = {
        name: "Header",
        type: "scatter",
        label: {
          "show": false,
        },
        xAxisIndex: xAxisIndex,
        yAxisIndex: yAxisIndex,
        gridIndex: gridIndex,
        markArea: {
          label: {
            backgroundColor: '#2F5EB3',
            color: 'white',
            padding: [0,0,10,0],
            fontSize: 8,
            fontWeight: 'bold',
            height: 24,
            distance: 1,
          },
          data: finalData
        },
      };
      if (includeMarkLine) {
        series.markLine = this.getDefaultMarkLineForEmotionalNeeds();
      }
      return series;
    }    
    getWinnersHeader(breakPointList,numberOfNeeds = 12,displayType = 'winner'){
      let header = this.getBaseNeedsHeader(breakPointList,numberOfNeeds,displayType);
      let returnHeader = {
        gridIndex: 0,
        
        position: "top",
        axisTick: {
            show: false,
        },
        axisLabel: {
            margin:5,
            height:20,
            fontSize: 12,
            fontFamily: "Franklin Gothic Demi Cond",
            fontWeight: 'bold',
            color: "#FFF",
            backgroundColor: "#2f5eb3",
            width: 30,
        },
        data: [""]
      }
      $.extend(true, header, returnHeader);
      return header;      
    }
    getFunctionalNeedsHeader(breakPointList, numberOfNeeds = 12,displayType = 'need'){
        let header = this.getBaseNeedsHeader(breakPointList,numberOfNeeds,displayType);
        let functionalNeedHeader = {
            gridIndex: 0,
            data: [""],
        }
        $.extend(true, header, functionalNeedHeader);
        return header;
    }
    getExpectationsConfig(containerId){
      let defaultSymbolType = SymbolService.getBarSymbol();
      let symbol = {
          symbol: defaultSymbolType,
          symbolSize : [21, 4],
          zLevel: 10,
          z:10
      };
      if (containerId == "value_emotional_needs_chart" || containerId == "profile_match_by_value_emotional_chart") {
        symbol.symbolSize = [4,21];
      }
      return symbol;
    }
    getCompetitorConfig(symbol = "circle",symbolSize = 10){
        return {
            symbol: symbol,
            symbolSize: symbolSize,
        };
    }
    getEmotionalNeedsHeader(breakPointlist, numberOfNeeds = 5,displayType = 'need'){
        let header = this.getBaseNeedsHeader(breakPointlist,numberOfNeeds,displayType );
        $.extend(true, header.axisLabel, {interval: 0});
        let emotionalNeedHeader = {
          gridIndex: 1,
          splitLine: {
            show: false,
          }
        }
        $.extend(true, header, emotionalNeedHeader);
        return header;
    }
    setEmotionalNeedsHeaderOrder(headerData, emotionalNeedsNameOrder){
      let newHeaderData = [];
      for (let i = 0; i < emotionalNeedsNameOrder.length; i++) {
        let emotionalNeedName = emotionalNeedsNameOrder[i];
        if (emotionalNeedName == "Stable") {
          newHeaderData.push("Assurance");
        } else if (emotionalNeedName == "Imaginative") {
          newHeaderData.push("Curiosity ");
        } else if (emotionalNeedName == "Precise") {
          newHeaderData.push("Strictness");
        } else if (emotionalNeedName == "Like-Minded") {
          newHeaderData.push("Trust");
        } else if (emotionalNeedName == "Connected") {
          newHeaderData.push("Activity");
        }          
      }
      return newHeaderData;
    }
    //Parse Data
    parseDriverListData(inputData, outputData){
      if (typeof inputData !== 'undefined' && inputData !== null) {
        if (typeof inputData.otherData !== 'undefined' && inputData.otherData !== null) {
          let otherData = inputData.otherData;
          outputData.driverList = [];
          if (typeof otherData.driverList !== 'undefined' && otherData.driverList !== null){
            outputData.driverList = JSON.parse(JSON.stringify(otherData.driverList));
          }
        }
      }
      return outputData;
    }
    
    /* Profile Specific Functions*/
    getMarkPointForEmotionalNeeds(includeBreakPoint = false){
      //console.log("Emotional Needs Name Order",emotionalNeedsNameOrder);
      let bottomEven = 1.2;
      let bottomOdd = 1.2;
      let topEven = 9.8;
      let topOdd = 9.8;
      let data = [
        {coord: ['Assurance',bottomOdd], value: 'Anxious'},
        {coord: ['Assurance',topOdd], value: 'Stable'},
        {coord: ['Activity',bottomEven], value: 'Detached'},
        {coord: ['Activity',topEven], value: 'Connected'},
        {coord: ['Trust',bottomOdd], value: 'Tough-Minded'},
        {coord: ['Trust',topOdd], value: 'Like-Minded'},          
        {coord: ['Curiosity',bottomEven], value: 'Practical'},
        {coord: ['Curiosity',topEven], value: 'Imaginative'},
        {coord: ['Strictness',bottomOdd], value: 'Flexible'},
        {coord: ['Strictness',topOdd], value: 'Precise'},          
      ];
      if (includeBreakPoint == true){
        data.push({coord: ['Assurance',5.5], value: '_______', symbolSize: .001});
        data.push({coord: ['Activity',5.5], value: '_______', symbolSize: .001});
        data.push({coord: ['Trust',5.5], value: '_______', symbolSize: .001});
        data.push({coord: ['Curiosity',5.5], value: '_______', symbolSize: .001});
        data.push({coord: ['Strictness',5.5], value: '_______', symbolSize: .001});
      }
      return {
          markPoint: {
            symbolSize: 1,
            label:{
              fontSize: 8,
              fontFamily: "Franklin Gothic Medium Cond",
              color: "#787878",
            },
            data: data,
          }
      }
    }
    buildLegendData(legend, displayNumberOfRespondents){
      let legendData = [];
      let baseLegendData = [];
      if (displayNumberOfRespondents && typeof legend.respondents != "undefined"){
        baseLegendData.push(...legend.respondents);
      } else {
        baseLegendData.push(...legend.normal);
      }
      let baseBarConfiguration = {     
        itemStyle: {
          borderColor: "white",
          borderWidth: 6.5,
        }        
      };
      for (let i = 0; i < baseLegendData.length; i++) {
        let legendEntry = {
          name: baseLegendData[i].name,
        };
        if (typeof baseLegendData[i].elementId != "undefined" && baseLegendData[i].elementId.includes("bar") ) {
          $.extend(true, legendEntry, baseBarConfiguration);
        }
        legendData.push(legendEntry);
      }
      return legendData;
    }
    getDefaultLegendProfileChart(){
        let legendTextStyle = { 
            color: "#000",
        };

        $.extend(true, legendTextStyle, ScatterService.getDefaultLegendTextStyle());
        return {
            show: ScatterService.getShowLegend(),
            textStyle: legendTextStyle,              
            borderColor: '#BFBFBF',
            width:"100%",
            orient: 'vertical',
            itemWidth: 15,
            left: 780,
            top:40, 
            borderWidth: 0,
        }
    }   
    getGridForProfileByNeedChart(includeBackgroud = true) {
        let grid1 = {
          left:90,
          top:80,
          width: 450,
          containLabel: false,
          bottom: 86
        };
        let grid2 = {
          top:80,
          left: 545,
          width: 200,
          containLabel: false,
          bottom: 86        
        };
        if (includeBackgroud == true){
            $.extend(true, grid1, ScatterService.getGridBackground());
            $.extend(true, grid2, ScatterService.getGridBackground());
        }
        let baseGrid = [];
        baseGrid.push(grid1);
        baseGrid.push(grid2);

        return baseGrid;
    }    

  getGridForValueDriverChart(includeBackgroud = true) {
    let grid1 = {
         top:70,
         left: 315,
         width: 450,
         containLabel: true,
         backgroundColor: "#e4e6e9",
         show: true         
     };
     let grid2 = {
         top:70,
         width: 220,
         containLabel: true,
         backgroundColor: "#fff",
         show: true         
     };
    let baseGrid = [];
    baseGrid.push(grid1);
    baseGrid.push(grid2);

    return baseGrid;
}    
/*Tooltip Functions */
toolTipForProfileAreaChart = (params) => {
  let returnValue = "";
  for (let i = 0; i < params.length; ++i) {
      let valueToDisplay = params[i].value;
      let seriesName = params[i].seriesName;
      //returnValue += params[i].marker += " ";
      returnValue += params[i].seriesName += " : ";
      returnValue += "<span style='text-align:right'><b>" 
      if (valueToDisplay > 0){
        if ("Pricing Power (Bar)" == seriesName) {
          returnValue += valueToDisplay;
          returnValue += "%";
        } else {
          returnValue += Number(valueToDisplay).toFixed(2);
        }
      } else {
          returnValue += "--";
      }
      returnValue += "</b></span>";
      returnValue += "<br>";
  }
  return returnValue;
}       
    setDimensionNameForProfileChart(name){
      if (name == "Like-Minded, Tough-Minded" || name == "Like-Minded,Tough-Minded"){
        return "Trust";
      } else if (name == "Precise, Flexible" || name == "Precise,Flexible"){
        return "Strictness";
      } else if (name == "Connected, Detached" || name == "Connected,Detached"){
        return "Activity";
      } else if (name == "Imaginative, Practical" || name == "Imaginative,Practical"){
        return "Curiosity";
      } else if (name == "Stable, Anxious" || name == "Stable,Anxious"){
        return "Assurance";
      }
      return "";
    }
    /* Utility Functions */
    drawSingleEmotionalNeedsName(name) {
      let value = name.split(",");
      if (typeof value != "undefined") {
        return value[0];
      }
      return name;
    }    
    isEmotionalNeedName(name) {
      let emotionalNeeds = ["Stable, Anxious","Imaginative, Practical","Precise, Flexible","Like-Minded, Tough-Minded","Connected, Detached",
        "Stable,Anxious","Imaginative,Practical","Precise,Flexible","Like-Minded,Tough-Minded","Connected,Detached"
      ];
      return emotionalNeeds.includes(name);
    }
}
export default new ProfileService();             
