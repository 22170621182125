import $, { data, type } from "jquery";
import CommonService from "./common.service";
import ScatterService from "./scatter.service";

class PredictiveAnalyticsService extends CommonService{
    /***  Series Functions ***/
    getSeries(name,gridIndex,xAxisIndex,yAxisIndex,config = {},isEmotionalNeeds = false){
        let baseSeries = {
            name: name,
            type: 'line',
            gridIndex: gridIndex,
            xAxisIndex: xAxisIndex,
            yAxisIndex: yAxisIndex,
            data: []
        };    
        $.extend(true, baseSeries, config);
        return baseSeries;
    }    
    setCorrelationColor(correlationClass){
        if (correlationClass == "very-strong-correlation"){
            return  "#548235";
        } else if (correlationClass == "strong-correlation"){
            return  "#70AD47";
        } else if (correlationClass == "mild-correlation"){
            return  "#A9D18E";
        } 
        return  "#000";
    }
    /***  Parse Functions ****/
    parseLegendAndColorData(inputData, outputData, moveFirstColorToEnd = true){
        outputData.colors = [];
        outputData.colors.push('#006600');
        outputData.colors.push('#737373');
        outputData.colors.push('#969696');
        outputData.colors.push('#bdbdbd');
        outputData.colors.push('#d9d9d9');
        outputData.colors.push('#f0f0f0');
        outputData.colors.push('#000000');
        outputData.colors.push('#525252'); 
        outputData.colors.push('#969696'); 
        outputData.colors.push('#d9d9d9'); 
        outputData.colors.push('#252525'); 
        outputData.colors.push('#737373'); 
        outputData.colors.push('#bdbdbd');
        outputData.colors.push('#f0f0f0');

        outputData.legend = {
            normal: [],
        };        
        if (typeof inputData !== 'undefined' && inputData !== null) {      
            if (typeof inputData.data !== 'undefined' && inputData.data !== null) {
                if (typeof inputData.data.metricDisplayTypeName !== 'undefined' && inputData.data.metricDisplayTypeName !== null) {
                    outputData.metricDisplayTypeName = inputData.data.metricDisplayTypeName;
                }       
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.returnMetricsList !== 'undefined' && inputData.data.metricsData.returnMetricsList !== null && inputData.data.metricsData.returnMetricsList.length > 0) {
                        let metricsList = inputData.data.metricsData.returnMetricsList;
                        for (let i = 0; i < metricsList.length; i++) {
                            let metric = metricsList[i];
                            if (typeof metric.axisName !== 'undefined' && metric.axisName !== null) {
                                let legendEntry = {
                                    name: metric.axisName,
                                    textStyle: {
                                        color: this.setCorrelationColor(metric.correlationClass),
                                        fontSize: 11,
                                        fontFamily: "Franklin Gothic Demi Cond"
                                    }
                                };
                                outputData.legend.normal.push(legendEntry);
                            }
                        }
                    }
                }                    
                if (typeof inputData.data.metricDisplayTypeName !== 'undefined' && inputData.data.metricDisplayTypeName !== null) {
                    outputData.metricDisplayTypeName = inputData.data.metricDisplayTypeName;
                }                

                if (typeof inputData.data.useMetricGrowth !== 'undefined' && inputData.data.useMetricGrowth !== null) {
                    outputData.useMetricGrowth = inputData.data.useMetricGrowth;
                }      
                if (typeof inputData.data.useMetricGrowthForSecondary !== 'undefined' && inputData.data.useMetricGrowthForSecondary !== null) {
                    outputData.useMetricGrowthForSecondary = inputData.data.useMetricGrowthForSecondary;
                }      
                if (typeof inputData.data.useSecondaryMetric !== 'undefined' && inputData.data.useSecondaryMetric !== null) {
                    outputData.useSecondaryMetric = inputData.data.useSecondaryMetric;
                }          
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationDataRow !== 'undefined' && inputData.data.metricsData.correlationDataRow.length > 0){                    
                        let correlationRow = inputData.data.metricsData.correlationDataRow[0];
                        if (typeof correlationRow.correlationData !== 'undefined' && correlationRow.correlationData !== null && correlationRow.correlationData.length > 0) {
                            outputData.correlation = correlationRow.correlationData[0];
                        }                    
                    }
                }
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationDataRow !== 'undefined' && inputData.data.metricsData.correlationDataRow.length > 0){                    
                        let correlationRow = inputData.data.metricsData.correlationDataRow[0];
                        if (typeof correlationRow.correlationClass !== 'undefined' && correlationRow.correlationClass !== null && correlationRow.correlationClass.length > 0) {
                            outputData.correlationClass = correlationRow.correlationClass[0];
                        }                    
                    }
                }
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.correlationDataRow !== 'undefined' && inputData.data.metricsData.correlationDataRow.length > 0){
                        let correlationRow = inputData.data.metricsData.correlationDataRow[0];
                        if (typeof correlationRow.correlationMessage !== 'undefined' && correlationRow.correlationMessage !== null && correlationRow.correlationMessage.length > 0) {
                            outputData.correlationMessage = correlationRow.correlationMessage[0];
                        }                    
                    }
                }
            }
        }
        return outputData;
    }

    parseData(chartNumber, inputData, outputData, itemsToRemove,useMetricGrowth = false,useSecondaryMetric = false){
        if (chartNumber == 1) {
          outputData.chart1 = {
            data: [],
            containerId: "predictive-analytics"
          };
        }      
        let finalMetricRows = [];
        if (typeof inputData !== 'undefined' && inputData !== null) {      
            if (typeof inputData.data !== 'undefined' && inputData.data !== null) {
                if (typeof inputData.data.metricsData !== 'undefined' && inputData.data.metricsData !== null) {
                    if (typeof inputData.data.metricsData.returnMetricsList !== 'undefined' && inputData.data.metricsData.returnMetricsList !== null && inputData.data.metricsData.returnMetricsList.length > 1) {
                        let metricsList = inputData.data.metricsData.returnMetricsList;
                        let rowData = [];
                        for (let metricListIndex = 0; metricListIndex < metricsList.length; metricListIndex++) {
                            let metric = metricsList[metricListIndex];
                            let metricRow = [];
                            if (typeof metric.metricRow !== 'undefined' && metric.metricRow !== null) {
                                metricRow = metric.metricRow;
                            }
                            let removeFirstRowCount = 0;
                            while (itemsToRemove.firstRows > removeFirstRowCount) {
                                let indexToRemove = 0;
                                metricRow.splice(indexToRemove, 1);
                                removeFirstRowCount++;
                            }
                            let removeRowCount = 0;
                            while (itemsToRemove.lastRows > removeRowCount) {
                                let indexToRemove = metricRow.length - 1;
                                metricRow.splice(indexToRemove, 1);
                                removeRowCount++;            
                            }           
                            let seriesData = {
                                name: "",
                                index: metricListIndex,
                                data: metricRow
                            };  
                            seriesData = JSON.parse(JSON.stringify(metric));       
                            if (metricListIndex == 0) {
                                seriesData.name = "primary";
                            } else if (metricListIndex == 1) {
                                seriesData.name = "secondary";
                            } else {
                                seriesData.name = "additional";
                            }        
                            seriesData.index = metricListIndex;                    
                            seriesData.data = metricRow;
                            finalMetricRows.push(seriesData);                 
                        }
                        for (let index = 0; index < finalMetricRows.length; index++) {
                            let seriesData = finalMetricRows[index]
                            let metricRows = seriesData.data;
                            let nd = [];          
                            for (let i = 0; i < metricRows.length; i++) {
                                let metricRow = metricRows[i];
                                if (typeof metricRow.chartData !== 'undefined' && metricRow.chartData !== null) {
                                    nd.push(metricRow.chartData);
                                }
                            }
                            seriesData.data = this.removeColumns(nd, itemsToRemove);
                            rowData.push(seriesData);
                        }
                        outputData.chart1.data.push(rowData);
                    }
                }
            }
        }
        return outputData;
    }
    getData(metricProperties,  wave, useMetricGrowth, metricDisplayTypeName){
        let value = useMetricGrowth == true ? metricProperties.metricGrowth : metricProperties.metric;
        if (value == 0){
            value = '--'
        } else if (metricDisplayTypeName == "Percentage" || metricDisplayTypeName == "Percent") {
            value = parseFloat(value).toFixed(1);
        } else if (metricDisplayTypeName == "Currency") {
            value = parseFloat(value).toFixed(2);
        } else if (metricDisplayTypeName == "Currency (tenths)") {
            value = parseFloat(value).toFixed(1);
        } else if (metricDisplayTypeName == "Currency (hundredths)") {
            value = parseFloat(value).toFixed(0);
        }
        let propertyData = {name: wave, value: value, displayType: metricDisplayTypeName};
        return propertyData;
    }
    setDataLabels(yAxis,useMetricGrowth,metricDisplayTypeName){
        /*let label = {
            formatter: "{value}"
        }*/
        if (useMetricGrowth == true) {
            $.extend(true, yAxis.axisLabel,{formatter: "{value} %"});            
        } else if (metricDisplayTypeName.includes("Currency")) {
            $.extend(true, yAxis.axisLabel,{formatter: "$ {value}"});
        } else if (metricDisplayTypeName.includes("Percentage") || metricDisplayTypeName.includes("Percent")) {
            $.extend(true, yAxis.axisLabel,{formatter: "{value} %"});
        } else {
            $.extend(true, yAxis.axisLabel,{formatter: "{value}"});
        }        
      //  $.extend(true, yAxis,{axisLabel: label});
        return yAxis;

    }
    /***  Series Functions ***/
    getSeriesForPredictiveAnalyticsChart(name,gridIndex,xAxisIndex,yAxisIndex,useMetricGrowth = false,metricDisplayTypeName = "Currency",symbol = "circle",chartOptions = {showLabels: true}){

        let baseSeriesConfig = {label: {show: chartOptions.showLabels ,color: '#000'},symbolSize: 9};
        if (useMetricGrowth == true) {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c} %"});
        } else if (metricDisplayTypeName.includes("Currency")) {
            $.extend(true, baseSeriesConfig.label,{formatter: "$ {c}"});
        } else if (metricDisplayTypeName.includes("Percentage") || metricDisplayTypeName.includes("Percent")) {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c} %"});
        } else {
            $.extend(true, baseSeriesConfig.label,{formatter: "{c}"});
        }        
        $.extend(true, baseSeriesConfig, this.getCompanyConfig(symbol));
        return this.getSeries(name,gridIndex,xAxisIndex,yAxisIndex,baseSeriesConfig)
    }
    /*** yAxis Functions ***/

    /*Tooltip Functions */
    toolTipForPredictiveAnalyticsChart = (params) => {
        let returnValue = "";
        for (let i = 0; i < params.length; ++i) {
            let valueToDisplay = params[i].value;
            let seriesName = params[i].name;        
            let data = params[i].data;
            returnValue += params[i].marker += " ";        
            returnValue += seriesName += " : ";
            returnValue += "<span style='text-align:right'><b>" 
            if (!isNaN(valueToDisplay)){
                let displayType = typeof data.displayType !== 'undefined' || data.displayType != null ? data.displayType : "Currency";
                if (displayType.includes("Currency") ) {
                    returnValue +=  "$" + parseFloat(valueToDisplay).toLocaleString();
                } else if (displayType == "Percentage" || displayType == "Percent") {
                    returnValue += parseFloat(valueToDisplay).toFixed(1) + "%";
                } else {
                    returnValue += valueToDisplay;
                }
            } else {
                returnValue += "--";
            }
            returnValue += "</b></span>";
            returnValue += "<br>";
        }
        return returnValue;
    }      
}    

export default new PredictiveAnalyticsService();             

