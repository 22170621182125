<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber) + ' dashboard-rank'">
    <div class="dashboard-header">    
        <div class="dashboard-title">
            <div class="dashboard-title-details">
            <a href="/analytics/ranks" @click="$parent.logViewDetailsEvent(title)">            
                <span v-html="chartTitleHtml"></span>
            </a>
            <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Predictive Analytics" />
            </div>
            <div class="dashboard-save-dashboard-card"  @click="$modal.show(modalName)"> 
                Save for Report
                <span class="m-1">
                  <font-awesome-icon :icon="['fa', 'floppy-disk']" />
                </span>
            </div>
        </div>
        <div class="dashboard-widgets dashboard-contributions">
            <dashboard-card-dropdown :label="isMoatScoreRank ? 'Survey Group' : 'Rank By Group'" :options="inputGroupOptions" 
                :read-only="readOnly" :default-value="surveyGroupId" :default-dropdown-option="isMoatScoreRank ? 'Please Choose' : 'No Ranking'" @change="saveSurveyGroup">
            </dashboard-card-dropdown>
            <dashboard-card-dropdown v-if="!isMoatScoreRank" label="# of Ranking Tiers" :options="number_of_ranking_tiers_options" 
                :default-value="numberOfTiers" :read-only="readOnly" @change="saveNumberOfRankingTiers">
            </dashboard-card-dropdown>
        </div>
        <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard"/>
    <div class="dashboard-card-body" v-show="displayDashboard" style="height: 250px">
          <w-score-rank-body 
            v-if="rankType == 'w-score-rank' && displayDashboard && showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :message="message" 
            :tier="tier" 
            />
          <moat-score-rank-body 
            v-if="isMoatScoreRank  && displayDashboard && showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :tier="tier" 
            :message="message" 
            :base-height="rankChartHeight"
            />
          <nps-score-rank-body 
            v-if="rankType == 'nps-rank' && displayDashboard && showRankDisplay"
            :tiers="tiers" 
            :title-html="titleHtml" 
            :score="score" 
            :tier="tier" 
            :net-promoter-score="netPromoterScore"
            :message="message" 
            :base-height="rankChartHeight"
            />
            <div :id="showNoRankingsId" v-if="showNoRankingDisplay && displayDashboard" class="dashboard-rank no-data-for-chart-container align-middle">
              <div class="no-data-for-chart" v-show="showNoRankingDisplay && displayDashboard" >
                No Rankings are currently 
                <br/>
                available to display.
                <br/><br/>
                Try selecting a different 
                <br/>Period or Group, 
                <br/>or create a new Group
              </div>
            </div>
    </div>
<!-- :updatedModalChartOptions.sync="options.series[0].data" -->         
    <dashboard-modal :modal-id="dashboardId" 
        :chart="this.chart" :client-chart="this.clientChart" 
        :dashboard-card-report-page-type-name="reportTypeName" 
        :show-rank-display="showRankDisplay"
        :modal-title="title" 
        :modal-chart-options="options" 
        :tiers="tiers" 
        :title-html="titleHtml" 
        :score="score" 
        :show-no-ranking-display="showNoRankingDisplay"
        :tier="tier" 
        :rank-type="rankType"
        :net-promoter-score="netPromoterScore"
        :message="message" 
      />
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import $ from "jquery";
import html2canvas from "html2canvas";

import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardCardDropdown from "./DashboardCardDropdown.vue";
import DashboardService from "../../services/dashboard.service.js";
import AdminService from "../../services/admin.service.js";
import PrintAndCopyService from "../../services/print.and.copy.service.js";
import ReportService from "../../services/report.service.js";
import SaveChart from "../chart/SaveChart.vue";
import WScoreRankBody from './rank/WScoreRankBody.vue';
import MoatScoreRankBody from './rank/MoatScoreRankBody.vue';
import NpsScoreRankBody from './rank/NpsScoreRankBody.vue';
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

export default {
  name: "DashboardRank",
  mixins: [DashboardMixins],
  components: {DashboardCardDropdown,SaveChart,WScoreRankBody,MoatScoreRankBody,NpsScoreRankBody,DashboardCardSkeleton},
  data() {
    return {
      modalName: "ranks-save-chart-confirmation",     
      title: "W-Score Rank",
      numberOfTiers: "5",
      surveyGroupId: "",
      chartTitleHtml: "",
      titleHtml: "",
      showRankDisplay: false,
      showNoRankingDisplay:false,
      tier: "",  
      score: 0,
      message: "",
      rankChartHeight: 235,
      dashboardGroupSettingType: "",
      dashboardNumberOfRankingTierSettingType: "",
      tiers: [],
      netPromoterScore: [],
      number_of_ranking_tiers_options: [
        {"name": "1","value": "1"},
        {"name": "2","value": "2"},
        {"name": "3","value": "3"},
        {"name": "4","value": "4"},
        {"name": "5","value": "5"},
        {"name": "10","value": "10"}
      ],
    };
  },
  props: {
    inputGroupOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },    
    rankType: {
        type: String,
        default: ""
    },
  },
  computed: {
    isMoatScoreRank: function() {
      return this.rankType == "moat-score-rank";
    },
    showNoRankingsId: function(){
		  if (this.showNoRankingDisplay && this.displayDashboard){
			  return this.containerId;
		  }
		  return "no-data";
	  },    
  },
  mounted() {
    this.setChartDetails();
    this.resetChart();
  },
  created() {},
  methods: {
    initialLoadData(){
      this.clearData();
      if (this.autoGenerateChartRun){
          this.processAutoGenerateChart();
      }  
      this.setChartDetails();
      this.updateCard();
    },
    loadData: function() {
      if (this.autoGenerateChartRun){
        this.initialLoadData();
      } else {
        this.displayDashboard = true; 
      }
      this.setChartDetails();
    },    
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("surveyGroupId",this.surveyGroupId);
      params.append("numberOfTiers",this.numberOfTiers);      
      return params;
    },      
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isSurveyGroupId = criteriaParameters[0] == "surveyGroupId" ? true : false;
  		if (isSurveyGroupId){
        this.surveyGroupId = criteriaParameters[1];
  		}
      let isNumberOfTiers = criteriaParameters[0] == "numberOfTiers" ? true : false;
  		if (isNumberOfTiers){
        this.numberOfTiers = criteriaParameters[1];
  		}      
    },    
    setChartDetails(){
      if (this.rankType == "w-score-rank"){
        this.title = "W-Score Rank";
        this.chartTitle = "W-Score Rank";
        this.chartTitleHtml = "W-Score <br/> Rank";
        this.titleHtml = "W-Score™";

        this.reportTypeName = "Dashboard - W-Score Rank";
        this.chartTypeName = "dashboard-w-score-rank";
        this.modalName = "w-score-rank-save-chart-confirmation";
        this.containerId = "w-score-rank-snap-id";
        this.dashboardGroupSettingType =  DashboardService.DASHBOARD_W_SCORE_RANK_GROUP_SETTING_TYPE;
        this.dashboardNumberOfRankingTierSettingType = DashboardService.DASHBOARD_W_SCORE_RANK_NUMBER_OF_TIERS_SETTING_TYPE;

      } else if (this.isMoatScoreRank){
        this.title = "Moat Score Rank";
        this.chartTitle = "Moat Score Rank";
        this.chartTitleHtml = "Moat Score <br/> Rank";
        this.titleHtml = "Moat Score";

        this.reportTypeName = "Dashboard - Moat Score Rank";
        this.chartTypeName = "dashboard-moat-score-rank";
        this.modalName = "moat-score-rank-save-chart-confirmation";
        this.containerId = "moat";

        this.dashboardGroupSettingType =  DashboardService.DASHBOARD_MOAT_SCORE_RANK_GROUP_SETTING_TYPE;
        this.dashboardNumberOfRankingTierSettingType = DashboardService.DASHBOARD_MOAT_SCORE_RANK_NUMBER_OF_TIERS_SETTING_TYPE;
      } else if (this.rankType == "nps-rank") {
        this.title = "NPS Rank";
        this.chartTitle = "NPS Rank";
        this.chartTitleHtml = "NPS <br/> Rank";
        this.titleHtml = "NPS®";

        this.reportTypeName = "Dashboard - NPS Rank";
        this.chartTypeName = "dashboard-nps-rank";
        this.modalName = "nps-rank-save-chart-confirmation";
        this.containerId = "nps";

        this.dashboardGroupSettingType =  DashboardService.DASHBOARD_NPS_RANK_GROUP_SETTING_TYPE;
        this.dashboardNumberOfRankingTierSettingType = DashboardService.DASHBOARD_NPS_RANK_NUMBER_OF_TIERS_SETTING_TYPE;
        
      }
    },
    clearData() {
      this.displayDashboard = false;
      this.showRankDisplay = false;
      this.showNoRankingDisplay = false;
      this.tier = "";  
      this.score = 0;
      this.message = "";
      this.tiers = [];
      this.netPromoterScore = [];
      this.numberOfTiers = "5";
      this.buildTiers();
    },
    saveSurveyGroup(value){
        this.surveyGroupId = value;
        this.saveSurveyGroupId();
        this.updateCard();
    },
    saveNumberOfRankingTiers(value){
        this.numberOfTiers = value;
        this.saveNumberOfTiers();
        this.updateCard();
    },
    updateCard: function() {
        this.displayDashboard = false;
        let vm = this;
        setTimeout(function() {
          if (!vm.autoGenerateChartRun){
            vm.setSurveyGroupId();
            vm.setNumberOfTiers();
          }
          if (vm.timePeriodId != "" && (!vm.isMoatScoreRank || vm.surveyGroupId != "") && vm.entrySurveyCode != "") {
            DashboardService.getRanks(vm.numberOfTiers, vm.surveyGroupId, vm.rankType, vm.timePeriodId, vm.entrySurveyCode,vm.getRanksCallback, vm);
          } else {
            vm.displayDashboard = true;
            vm.showNoRankingDisplay = !this.showRankDisplay;
          }
        }, 100);
    },    
    getRanksCallback(response){
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        if (response.data.data.score != "NaN"){
          this.score = response.data.data.score;
        } else {
          this.score = 0;
        }
        this.message = response.data.data.message;
        this.tier = response.data.data.tier;
        this.showRankDisplay = response.data.data.showRankDisplay;
        this.showNoRankingDisplay = !this.showRankDisplay;
        this.netPromoterScore = response.data.data.netPromoterScore;
        this.displayDashboard = true;
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveNumberOfTiers() {
      //console.log("saveNumberOfTiers: " + this.rankType);
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardNumberOfRankingTierSettingType, this.numberOfTiers);
    },
    saveSurveyGroupId() {
      //console.log("saveSurveyGroupId: " + this.rankType);
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardGroupSettingType, this.surveyGroupId);
    },
    setSurveyGroupId() {
      /* WRAT-753 Moat Score Rank on Dashboard - Start
      */ // WRAT-753 Moat Score Rank on Dashboard - End
      if (this.isMoatScoreRank){
        this.surveyGroupId = DashboardService.setW30AsDefaultSurveyGroup(this.inputGroupOptions, this.surveyGroupId);
      }
      this.surveyGroupId = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardGroupSettingType,
          this.surveyGroupId, this.inputGroupOptions);

      let surveyGroupIdFound = this.inputGroupOptions.find(x => x.value == Number(this.surveyGroupId));
      if (typeof surveyGroupIdFound == "undefined") {
        this.surveyGroupId = "";
      }           
    },
    setNumberOfTiers() {
      //console.log("setNumberOfTiers: ");
      this.numberOfTiers = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, this.dashboardNumberOfRankingTierSettingType,
        this.numberOfTiers, this.inputGroupOptions);
    },
    buildTiers(){
      this.tiers = [];
      // create an array based on the number of tiers
      for (let i = this.numberOfTiers; i > 0; i--) {
        this.tiers.push({ name: i + "W"});
      }      
    },
    async saveReportPage(containerId, reportTypeName) {
      if (this.clientChart.clientChartId != 0   && this.isGenerateReportRunning()) {

        let reportPageChart = $("#" + containerId);
        let reportPageChartObject = reportPageChart[ 0 ];        
        let imageReportPageChartContent = await this.createChartImage(reportPageChartObject, false, true, 10); 
        await ReportService.saveReportPage(imageReportPageChartContent, reportTypeName, this.clientChart, this.saveReportPageCallback, this);
        await ReportService.saveReportPageFile(this.imageUrl, "png", reportTypeName, this.clientChart, this.saveReportPageFileCallback, this);
      }
    },    
    saveReportPageCallback(){},
    saveReportPageFileCallback(){},
    async createChartImage(chartContainer, keyOnly = false, includeRemoveBox = true, scale = 10, imageClass = "pdf-content-image-width"){
      let vm = this;
      chartContainer = PrintAndCopyService.getChartContainer(chartContainer, keyOnly, includeRemoveBox);
      
      await html2canvas(chartContainer, PrintAndCopyService.getHTML2CanvasOptions(false,scale)).then(async function (canvas) {
        vm.imageUrl = canvas.toDataURL();
      });
      let myImage = $('<img/>');
      myImage.attr('src', this.imageUrl);
      myImage.attr('class', imageClass);

      let imageReportPageChartContent = myImage
          .wrapAll("<div>")
          .parent()
          .html();
      return imageReportPageChartContent;
    },
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardRank::entrySurveyCode - " + this.rankType);;
      this.resetChart();
    },
    timePeriodId: function() {
      if (this.timePeriodId != "") {
      // eslint-disable-next-line no-console
      //console.log("DashboardRank::timePeriodId - " + this.rankType);
        this.initialLoadData();
      }
    },
    numberOfTiers: function() {
      this.buildTiers();
    }

  },
};
</script>
